import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { MenuContext } from "../../../context/MenuContext";
import { useGetListaHistorialQuery } from "../../../redux/services/historial";
import { ModalidadContext } from "../../../context/ModalidadContext";
import MenuLateral from "../../../components/MenuLateral";
import noimage from "../../../static/img/sinfoto.png";
import { useSelector } from "react-redux";

const HistorialPublico = () => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const { menuState } = useContext(MenuContext);
  const [historial, setHistorial] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const state = useSelector((state) => state.modalidad);

  const { data: dataHistorial, isLoading: isLoadingHistorial } =
    useGetListaHistorialQuery(state?.modalidad.id);
  const { modalidadState, setModalidadState } = useContext(ModalidadContext);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataHistorial?.listaHistorial;
        setHistorial(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [state, dataHistorial, setModalidadState]);

  const totalPages = Math.ceil(historial?.length / pageSize);

  const paginatedHistorial = historial?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setModalidadState(state);

    return () => {
      setModalidadState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu2 = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const procesarTemporadas = (historial) => {
    let temporada = {
      inicio: [],
      fin: [],
    };
    historial.forEach((posicion) => {
      const [desde, hasta] = posicion.temporada.split("/").map(Number);
      temporada.inicio.push(desde);
      temporada.fin.push(hasta);
    });

    return temporada;
  };

  const temporada = paginatedHistorial
    ? procesarTemporadas(paginatedHistorial)
    : { temporada: null };

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral
          opcMenu={
            state?.modalidad.nombre.toLowerCase() === "cnd" ? opcMenu : opcMenu2
          }
          menuTitulo={state?.modalidad.nombre}
          typeMenu={false}
        />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          {isLoadingHistorial && (
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                className="Titulo"
                variant="h5"
                style={{
                  width: "100%",
                  textAlign: "center",
                  zIndex: 1,
                  paddingRight: "5px",
                }}
              >
                Cargando Historial de {state?.modalidad.nombre}
              </Typography>
              <CircularProgress
                style={{ position: "relative", height: "15px", width: "15px" }}
              />
            </div>
          )}
          {!isLoadingHistorial &&
            paginatedHistorial &&
            paginatedHistorial.length > 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell
                          className="Titulo"
                          colSpan={4}
                          align="center"
                        >
                          <Typography variant="h5">
                            Historial de {state?.modalidad.nombre}
                          </Typography>
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {paginatedHistorial.map((posicion, indice) => (
                          <TableRow key={indice}>
                            <TableCell align="left" style={{ width: "15%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Temporada:{" "}
                                </span>
                                {temporada.inicio[indice] ===
                                temporada.fin[indice]
                                  ? temporada.inicio[indice]
                                  : `${posicion.temporada}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ width: "60%" }}>
                              <Typography variant="body1" className="Titulo">
                                Pilotos:
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    posicion?.piloto1?.foto
                                      ? `${API_BASE_URL}/images/${posicion.piloto1.foto}`
                                      : noimage
                                  }
                                  alt={posicion.piloto1.nombre}
                                  title={posicion.piloto1.nombre}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "fill",
                                    borderRadius: 20,
                                    marginRight: 50,
                                  }}
                                />
                                <div>
                                  <Typography variant="body2">
                                    <span style={{ fontWeight: "bold" }}>
                                      1º {posicion.piloto1.nombre}
                                    </span>
                                    ({posicion.piloto1.ciudad},{" "}
                                    {posicion.piloto1.provincia})
                                  </Typography>
                                  <Typography variant="body2">
                                    <span style={{ fontWeight: "bold" }}>
                                      2º {posicion.piloto2.nombre}
                                    </span>{" "}
                                    ({posicion.piloto2.ciudad},{" "}
                                    {posicion.piloto2.provincia})
                                  </Typography>
                                  <Typography variant="body2">
                                    <span style={{ fontWeight: "bold" }}>
                                      3º {posicion.piloto3.nombre}
                                    </span>{" "}
                                    ({posicion.piloto3.ciudad},{" "}
                                    {posicion.piloto3.provincia})
                                  </Typography>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                PDF:
                              </Typography>
                              {posicion.pdf === "null" ||
                              posicion.pdf === null ? (
                                <Typography style={{ marginLeft: "10px" }}>
                                  <FileDownloadOffIcon color="primary" />
                                </Typography>
                              ) : (
                                <IconButton
                                  component="a"
                                  href={`${API_BASE_URL}/images/${posicion?.pdf}`}
                                  download={`${posicion?.nombrePdf}`}
                                  target="_blank"
                                >
                                  <GetAppIcon color="primary" />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell align="left" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                Link:
                              </Typography>
                              {posicion.info === "null" ||
                              posicion.info === null ? (
                                <Typography
                                  sx={{
                                    mx: 1,
                                    textAlign: "center",
                                    color: "black",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <HorizontalRuleIcon color="primary" />
                                </Typography>
                              ) : (
                                <Typography
                                  component="a"
                                  target="_blank"
                                  sx={{
                                    mx: 1,
                                    textAlign: "center",
                                    color: "black",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                  }}
                                  href={
                                    posicion.info.startsWith("http")
                                      ? posicion.info
                                      : `http://${posicion.info}`
                                  }
                                >
                                  <InfoIcon color="primary" />
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider sx={{ width: "100%", m: 2 }} />
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} / {totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              </Grid>
            )}
          {!isLoadingHistorial && (!historial || historial.length === 0) && (
            <Typography className="Titulo">
              No hay Historial para mostrar.
            </Typography>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HistorialPublico;
