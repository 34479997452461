import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuLateral from "../../../../components/MenuLateral";
import { MenuContext } from "../../../../context/MenuContext";
import dayjs from "dayjs";
import { useGetMostrarMangasRankingQuery } from "../../../../redux/services/ranking";

const MangasDetalle = () => {
  const { menuState } = useContext(MenuContext);
  const [armadoRanking, setArmadoRanking] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { state } = useLocation();

  const { data, isLoading: isLoadingArmadoRanking } =
    useGetMostrarMangasRankingQuery({
      idCompetidor: state.posicion.id,
      idModalidad: state.modalidad.id,
    });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = data?.listaArmadoRanking;
        setArmadoRanking(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [state, data]);

  const totalPages = Math.ceil(armadoRanking?.length / pageSize);

  const paginatedArmadoRanking = armadoRanking?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const opcMenu = [
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral opcMenu={opcMenu} menuTitulo={"Detalle Mangas"} typeMenu={false} />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          {isLoadingArmadoRanking && (
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                className="Titulo"
                variant="h5"
                style={{
                  width: "100%",
                  textAlign: "center",
                  zIndex: 1,
                  paddingRight: "5px",
                }}
              >
                Cargando Ranking de Parapente XC
              </Typography>
              <CircularProgress
                style={{ position: "relative", height: "15px", width: "15px" }}
              />
            </div>
          )}
          {!isLoadingArmadoRanking &&
            paginatedArmadoRanking &&
            paginatedArmadoRanking.length > 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell
                          className="Titulo"
                          colSpan={4}
                          align="center"
                        >
                          <Typography variant="h5">
                            {state.posicion.nombre} - Puntuacion:{" "}
                            {state.posicion.puntuacion}{" "}
                          </Typography>
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {paginatedArmadoRanking.map((manga, indice) => (
                          <TableRow key={indice}>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{ display: "block" }}
                                >
                                  Lugar:{" "}
                                </span>
                                {manga.tipo} - {manga.competencia} - T
                                {manga.manga}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "40%" }}>
                              <Typography variant="body1" className="Titulo">
                                Fecha:
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ fontWeight: "bold" }}>
                                  {dayjs(manga.fechaFin).format("DD-MM-YYYY")}
                                </span>{" "}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                PuntosxCoef:
                              </Typography>
                              <Typography
                                target="_blank"
                                sx={{
                                  mx: 1,
                                  textAlign: "center",
                                  color: "black",
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                {manga.crudo}X{manga.multiAnti}X
                                {manga.multiTipo}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                Valor Actual:
                              </Typography>

                              <Typography
                                target="_blank"
                                sx={{
                                  mx: 1,
                                  textAlign: "center",
                                  color: "black",
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                {parseFloat(manga.puntos).toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider sx={{ width: "100%", m: 2 }} />
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} / {totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              </Grid>
            )}
          {!isLoadingArmadoRanking &&
            (!armadoRanking || armadoRanking.length === 0) && (
              <Typography className="Titulo">
                No hay Ranking para mostrar.
              </Typography>
            )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default MangasDetalle;
