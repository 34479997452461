import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import ModalTipoRecord from "./ModalTipoRecord";

const ListaTipoRecord = ({ Tipo, Origen }) => {
  const [modalState, setModalState] = React.useState(false);
  const [currentId, setCurrentId] = React.useState("");

  const handleOpenModal = () => {
    setCurrentId(Tipo.id);
    setModalState(true);
  };

  const handleCloseModal = () => {
    setModalState(false);
  };

  return (
    <React.Fragment>
      <Accordion className="Acordeon">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
        >
          <Typography className="Titulo" sx={{ width: "75%" }}>
            {Tipo.nombre}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ width: "auto", height: "auto", m: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails className="AcordeonDetail ">
          <Typography className="Texto truncate-text">{""}</Typography>
          <Button className="BotonBody" onClick={handleOpenModal}>
            Ver mas
          </Button>
          <div style={{ display: "flex" }}>
            <ModalTipoRecord
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Tipo={Tipo}
              CurrentId={currentId}
              Origen={Origen}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ListaTipoRecord;
