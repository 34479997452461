import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import FAVL_Logo from '../static/img/FAVL-logo1.png'
import './Styles/BarraNavegacion.css'
import { AppBar, Button, Grid, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import authSlice from '../redux/features/authSlice'
import { useSelector } from "react-redux";

function BarraNavegacion() {

  const { accessToken } = useSelector((state) => state.auth);

  const botonesNav = [
    {
      nombre: "Modalidades",
      link: "/",
    },
    {
      nombre: "Novedades y Calendario",
      link: "/Inicio",
    },
    {
      nombre: "Seleccion",
      link: "/SeleccionInicio",
    },
    {
      nombre: "Admin",
      link: accessToken ? "/cms" : "/Login",
    },
  ];

  const isMobile = window.innerWidth <= 768;

  const [isVisible, setIsVisible] = React.useState(false)
  return (
    <React.Fragment>
      <AppBar className="AppBar">
        <Toolbar>
          <Grid container className="Grid">
            <Grid item xs={12} md={2} className="favl-logo">
              <img title='Logo' src={FAVL_Logo} aria-label='logo favl' className="gridImagen"></img>
              {isMobile
                &&
                <IconButton onClick={() => { setIsVisible(!isVisible) }} aria-label="menu">
                  <MenuIcon />
                </IconButton>}
            </Grid>
            {
              isMobile
              && isVisible
              &&
              <>
                {
                  botonesNav.map((boton, i) => (
                    <Grid container
                      justifyContent="center"
                      sx={{ mt: 2 }}>
                      <Button
                        key={i}
                        component={Link}
                        to={boton.link}
                        size="small"
                      >
                        {boton.nombre}
                      </Button>
                    </Grid>
                  ))
                }
              </>
            }
            {
              !isMobile
              &&
              <Grid item xs={12} md={10} className="gridBotones">
                {botonesNav.map((boton, i) => (
                  <Button
                    key={i}
                    component={Link}
                    to={boton.link}
                    size="small"
                    className='botonBN'
                  >
                    {boton.nombre}
                  </Button>
                ))}
              </Grid>}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment >
  );
}

export default BarraNavegacion