import React, { useContext } from "react";
import "../Styles/ParapenteXC.css";
import MenuLateral from "../../components/MenuLateral";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../context/MenuContext";

function Cms({ children, nombre }) {
  const opcMenu = [
    {
      opcion: "Inicio ",
      link: "/cms",
    },
    {
      opcion: "Novedades",
      link: "/Novedades",
    },
    {
      opcion: "Competencias",
      link: "/Competencias",
    },
    {
      opcion: "Resultados",
      link: "/Resultados",
    },
    {
      opcion: "Ranking",
      link: "/RankingAdmin",
    },
    {
      opcion: "Historial",
      link: "/Historial",
    },
    {
      opcion: "Record",
      link: "/Record",
    },
    {
      opcion: "Competidores",
      link: "/Competidores",
    },
    {
      opcion: "Selección",
      link: "/Seleccion",
    },
    {
      opcion: "Club",
      link: "/Club",
    },
    {
      opcion: "Reglamentos ",
      link: "/ReglamentosModalidades",
    },
    {
      opcion: "Modalidades",
      link: "/Modalidades",
    },
    {
      opcion: "Tipo de Competencias",
      link: "/Tipo de Competencias",
    },
    {
      opcion: "Tipo Record",
      link: "/TipoRecord",
    },
    {
      opcion: "Categoría",
      link: "/Categoria",
    },
    {
      opcion: "Ajustes",
      link: "/Ajustes",
    },
    // {
    //   opcion: "Cerrar Sesión",
    //   link: ''
    // },
  ];
  const { setMenuState } = useContext(MenuContext);

  const location = useLocation();

  const currentPath =
    location.pathname.replace("/", "") === "cms"
      ? "Contenido"
      : location.pathname.replace("cms/", "") === "SubirResultados" ||
        location.pathname.replace("cms/", "") === "VerResultados"
      ? location.pathname.slice(1).replace(/[A-Z]/g, " $&")
      : location.pathname.replace("/", "").replace(/%20/g, " ");

  return (
    <React.Fragment>
      <MenuLateral
        setEstadoMenu={setMenuState}
        opcMenu={opcMenu}
        menuTitulo={`Gestión ${nombre ? nombre : currentPath}`}
        typeMenu={true}
      />
      {children}
    </React.Fragment>
  );
}

export default Cms;
