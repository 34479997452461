import React from "react";
import "./Styles/Home.css";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import BarraNavegacion from "../components/BarraNavegacion";
import ModalidadCard from "../components/ModalidadCard.js";
import PieDePagina from "../components/PieDePagina";
import { MenuContext } from "../context/MenuContext.js";
import { useGetListaModalidadesQuery } from "../redux/services/modalidadHome.js";


function Home() {
  const [modalidadData, setModalidadData] = React.useState([]);

  const { data: dataCategorias, isLoading: isLoadingModalidad } =
    useGetListaModalidadesQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const postsData = dataCategorias?.listaModalidades?.filter(
          (modalidad) => modalidad.activo !== false
        );
        setModalidadData(postsData);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataCategorias]);

  const { setMenuState } = React.useContext(MenuContext);

  React.useEffect(() => {
    setMenuState(false);

    return () => {
      setMenuState(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingModalidad) {
    return (
      <React.Fragment>
        <BarraNavegacion />
        <Grid
          container
          rowSpacing={4}
          spacing={4}
          sx={{ mt: "5rem", mb: "2rem" }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" component="h1" className="tituloContenido">
              Modalidades de competencias
            </Typography>
          </Grid>
        </Grid>
        <Box style={{ width: "80%", height: "auto" }} sx={{ mx: "auto" }}>
          <Grid
            container
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "100%", justifyContent: "center" }}
            spacing={2}
          >
            {Array.from(new Array(8)).map((card, index) => (
              <Grid
                key={index}
                item
                sx={{ pt: 2 }}
                xs={3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box sx={{ width: 250, marginRight: 0.5, my: 5 }}>
                  <Skeleton variant="rectangular" width={250} height={250} />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <PieDePagina />
      </React.Fragment>
    );
  }

  return (
    <>
      <BarraNavegacion />
      <>
        <Grid
          container
          rowSpacing={4}
          spacing={4}
          sx={{ mt: "5rem", mb: "2rem" }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" component="h1" className="tituloContenido">
              Modalidades de competencias
            </Typography>
          </Grid>
        </Grid>
        <Box style={{ width: "80%", height: "auto" }} sx={{ mx: "auto" }}>
          <Grid
            container
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "100%", justifyContent: "center" }}
            spacing={2}
          >
            {!isLoadingModalidad && !modalidadData ? (
              <Box
                sx={{
                  height: "50vh",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography className="Titulo" sx={{ textAlign: "center" }}>
                  {" No hay modalidades para mostrar."}
                </Typography>
              </Box>
            ) : (
              modalidadData?.map((modalidad) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  key={modalidad.id}
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{}}
                >
                  {
                    <ModalidadCard
                      linkTo={`/modalidad/${modalidad.nombre}`}
                      modalidad={modalidad}
                      state={modalidad}
                      color={
                        "#" + (((1 << 24) * Math.random()) | 0).toString(16)
                      }
                    />
                  }
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </>
      <PieDePagina />
    </>
  );
}

export default Home;
