import React, { useContext, useEffect, useState } from "react";
import "../../../../../components/Styles/Calendario.css";
import formato1 from "../../../../../static/img/formato1.png";
import formato2 from "../../../../../static/img/formato2.png";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MenuContext } from "../../../../../context/MenuContext";
import { useAgregarHtmlMutation } from "../../../../../redux/services/resultados";
import CargaManual from "./CargaManual";
import ListarResultadoCompetidor from "./ListarResultadoCompetidor";
import TableHistorial from "./TableHtml";

function SubirResultadosHtml({ sinmenu }) {
  const [dataHtml, setDataHtml] = React.useState();
  const { state } = useLocation();
  const { menuState } = useContext(MenuContext);
  const [open, setOpen] = React.useState(false);
  const [fileNameResult, setFileNameResult] = useState("");
  const [extractedTable, setExtractedTable] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const hiddenInputRefResult = React.createRef();

  const handleOpenFileDialogResult = () => {
    hiddenInputRefResult.current.click();
  };

  const HiddenInput = styled("input")({
    display: "none",
  });

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    if (value)
      setDataHtml({
        ...dataHtml,
        [name]: value,
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileNameResult(file?.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const htmlContent = event.target.result;
        const segundaTabla = convertirSegundaTablaEnArrayDeObjetos(htmlContent);
        setExtractedTable(segundaTabla);
      };
      reader.readAsText(file);
    } else {
      setFileNameResult("");
    }
  };

  const [agregarHtml, error, isFetching] = useAgregarHtmlMutation();

  const handleSubirHtml = async (e) => {
    e.preventDefault();

    try {
      handleOpen();

      if (extractedTable[0].id === undefined) {
        toast.error(
          "Error al cargar el HTML. Antes de cargar el HTML, asegúrese de seleccionar primero el formato correcto.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }

      if (extractedTable) {
        setMostrarTabla(true);
      }
    } catch (error) {
      toast.error("Error subiendo resultados de Competencia!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error updating competencia:", error);
    } finally {
      setFileNameResult("");
      handleClose();
    }
  };

  const convertirSegundaTablaEnArrayDeObjetos = (htmlContent) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlContent, "text/html");
    const tablas = document.querySelectorAll("table");
    if (dataHtml.formato === "2" || dataHtml.formato === 2) {
      if (tablas.length < 2) {
        console.error("No se encontró la segunda tabla en el documento.");
        return [];
      }

      const segundaTabla = tablas[1];
      const filas = segundaTabla.querySelectorAll("tbody tr");
      const columnas = segundaTabla.querySelectorAll("thead th");

      const datos = [];

      filas.forEach((fila) => {
        const filaDatos = {};
        fila.querySelectorAll("td").forEach((celda, indice) => {
          filaDatos[columnas[indice].textContent.toLowerCase()] =
            celda.textContent;
        });
        datos.push(filaDatos);
      });

      return datos;
    }

    if (dataHtml.formato === "1" || dataHtml.formato === 1) {
      if (tablas.length < 2) {
        console.error("No se encontró la segunda tabla en el documento.");
        return [];
      }

      const primeraTabla = tablas[0];
      const filas = primeraTabla.querySelectorAll("tbody tr");
      const columnas = primeraTabla.querySelectorAll("thead th");

      const datos = [];

      filas.forEach((fila) => {
        const filaDatos = {};
        fila.querySelectorAll("td").forEach((celda, indice) => {
          filaDatos[columnas[indice].textContent.toLowerCase()] =
            celda.textContent;
        });
        datos.push(filaDatos);
      });

      return datos;
    }
  };

  useEffect(() => {
    setDataHtml((prevValues) => ({ ...prevValues, id: state.id, formato: 1 }));
    return () => {
      setDataHtml();
    };
  }, []);

  useEffect(() => {
    if (!isFetching) handleClose();
  }, [isFetching]);

  const isMobile = window.innerWidth <= 768;
  const style = isMobile ? {} : { justifyContent: 'center' };

  return (
    <React.Fragment>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
        style={{ marginTop: "7%" }}
      >
        <Box className="Acordeon">
          <Box className="AcordeonSummary">
            <Typography className="Titulo" sx={{ width: "80%" }}>
              {state.titulo}
            </Typography>
            <Typography
              className="Subtitulo textoPequeñoCal"
              style={{ fontSize: 16 }}
            >
              Fin: {dayjs(state.fechaFin).format("DD-MM-YYYY")}
            </Typography>
          </Box>

          <Paper
            sx={{ py: 10 }}
            style={{
              width: "98%",
              alignContent: "center",
              alignSelf: "center",
              height: "auto",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", padding: 2 }}
              >
                <Grid item xs={12}>
                  <Typography
                    className="Titulo"
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "16px", sm: "18px", md: "20px" },
                      padding: { xs: "10px", sm: "15px", md: "20px" },
                    }}
                  >
                    Por favor, seleccione el formato antes de cargar el HTML
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                  padding: 20,
                }}
              >
                <TextField
                  label="Resultados .html"
                  name="htmlFile"
                  value={fileNameResult || ""}
                  variant="outlined"
                  autoComplete="off"
                  onClick={handleOpenFileDialogResult}
                  InputProps={{ readOnly: true }}
                  sx={{ bgcolor: "transparent", minWidth: "50%" }}
                />
                <HiddenInput
                  name="htmlFile"
                  type="file"
                  ref={hiddenInputRefResult}
                  accept=".html"
                  onChange={handleFileChange}
                />
                <Button
                  onClick={handleSubirHtml}
                  className="BotonBody"
                  style={{ padding: 15 }}
                >
                  SUBIR
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <RadioGroup
                  aria-labelledby="radio-buttons-group-formato-html"
                  defaultValue="1"
                  name="formato"
                  onChange={handleChange}
                >
                  <Tooltip
                    title={
                      <img
                        src={formato2}
                        style={{ width: 50, height: 100 }}
                        alt="Formato 1"
                      />
                    }
                    arrow
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={"Formato 1, tabla chica abajo"}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <img
                        src={formato1}
                        style={{ width: 50, height: 100 }}
                        alt="Formato 2"
                      />
                    }
                    arrow
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={"Formato 2, tabla chica arriba"}
                    />
                  </Tooltip>
                </RadioGroup>
              </Grid>
            </Grid>
          </Paper>
          {mostrarTabla ? (
            <Box>
              <Grid container className="GridItemCN" justifyContent="center">
                <Grid item xs={12}>
                  <Typography
                    className="Titulo"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Tabla HTML
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className="GridItemCN"
                sx={{ margin: "10px", overflowX: "auto" }}
                style={style}
              >
                <TableHistorial extractedTable={extractedTable} />
              </Grid>
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box>
            <Grid
              container
              className="GridItemCN"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  className="Titulo"
                  style={{ textAlign: "center", fontSize: "30px" }}
                >
                  Carga Manual
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="GridItemCN" sx={{ margin: "10px" }}>
              <ListarResultadoCompetidor />
              <CargaManual sinmenu={true} />
            </Grid>
          </Box>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div
          id="div1"
          style={{
            p: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ textAlign: "center", fontSize: 10 }}>
            Subiendo Resultados...{" "}
          </p>
          <p style={{ textAlign: "center", color: "orange" }}>
            No actualice esta página
          </p>
        </div>
      </Backdrop>
    </React.Fragment>
  );
}

export default SubirResultadosHtml;
