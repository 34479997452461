import React from "react";
import "../../../../../components/Styles/Calendario.css";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

function MostrarResultados({ competencia, Origen }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <React.Fragment>
      <Paper className="Acordeon" sx={{ mb: 2, p: 1 }}>
        <Grid container rowSpacing={2} Spacing={2}>
          <Grid item xs={12} md={8}>
            <Box className="AcordeonSummary">
              <Typography className="Titulo" sx={{ width: "100%" }}>
                {competencia.titulo}
              </Typography>
              <Typography
                className="Subtitulo textoPequeñoCal"
                sx={{ textAlign: "left" }}
              >
                Fecha de Finalización:{" "}
                {dayjs(competencia.fechaFin).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          </Grid>
          <Box flexGrow={1} />
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                navigate("/VerResultadosPublicados", {
                  state: { ...state, competencia },
                });
              }}
              className="BotonBody"
              style={{ padding: 5 }}
            >
              VER{" "}
            </Button>
            {competencia.asunto &&
            (competencia.asunto !== "null" ||
              competencia.asunto !== "undefined") &&
            (competencia.asunto.startsWith("http://") ||
              competencia.asunto.startsWith("https://") ||
              competencia.asunto.startsWith("www.")) ? (
              <IconButton
                component="a"
                href={
                  competencia.asunto.startsWith("www.")
                    ? "https://" + competencia.asunto
                    : competencia.asunto
                }
                target="_blank"
              >
                <InfoIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton disabled component="a" href={"#"}>
                <HorizontalRuleIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default MostrarResultados;
