import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useBorrarRecordByIdMutation,
  useModificarRecordMutation,
} from "../../../../../redux/services/record";
import { useGetListaCategoriasQuery } from "../../../../../redux/services/categoria";
import { useGetCompetidorByIdQuery } from "../../../../../redux/services/competidor";
import { useGetListaTiposRecordsQuery } from "../../../../../redux/services/tipoRecord";

const ModalEditarEliminarRecord = ({ row, accion, open, handleClose }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    territorio: false,
    idPiloto: false,
    despegue: false,
    provincia: false,
    tipo: false,
    track: false,
    marca: false,
    idCategoria: false
});


const initialState = {
    territorio: 'Nacional',
    idPiloto: 0,
    provincia: '',
    tipo: '',
    idTipo: 19,
    idCategoria: 0,
    despegue: '',
    track: '',
    marca: '',
    fecha: dayjs().format('YYYY-MM-DD'),
    activo: true,
    estado: ''
}


const [newRecord, setNewRecord] = React.useState(initialState);
useEffect(() => {
    setNewRecord({ ...newRecord, ...row });

    return () => {
        setNewRecord()
    }
}, [row])


const handleButtonAction = (e) => {

    if (accion === 'Editar') {
        handleEditar(e);
    } else if (accion === 'Eliminar') {
        handleEliminar(e);
    }
    // handleClose(); // Cerrar el modal después de realizar la acción
};


// const [legajo, setLegajo] = React.useState(null);
const [piloto, setPiloto] = React.useState('')

const [hasError, setHasError] = React.useState({
    categoria: false,
});

const [dataCategorias, setDataCategorias] = React.useState();
const { data: resCategorias, isLoading: isLoadingCategorias } = useGetListaCategoriasQuery();

React.useEffect(() => {

    try {
        setDataCategorias(resCategorias?.listaCategorias)
    } catch (error) {
        console.log('Error al obtener las categorias:', error)
    }

    return () => {
        setDataCategorias();
    }
}, [resCategorias]);

const handleChangeLicencia = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setNewRecord(prevRecord => {
        return {
            ...prevRecord,
            idPiloto: value
        };
    });
}
const handleBlurLicencia = (e) => {
    e.preventDefault();
    const { value } = e.target;

    let errorMessage = '';

    if (value.trim() === '' || isNaN(value) || parseFloat(value) <= 0) {
        errorMessage = 'Este campo es obligatorio';
        setValidationErrors(prevErrors => {
            let newOBjet = {
                ...prevErrors,

            }
            newOBjet.idPiloto = true
            return { ...newOBjet }
        });
    } else {
        setValidationErrors(prevErrors => {
            let newOBjet = {
                ...prevErrors,

            }
            newOBjet.idPiloto = false
            return { ...newOBjet }
        });
    }

    if (errorMessage === '') {
        setNewRecord(prevRecord => {
            return {
                ...prevRecord,
                idPiloto: value
            };
        });
    }

    if (!dataLicencia) {
        toast.error("No existe un competidor con esa Licencia!");
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            // eslint-disable-next-line eqeqeq
            idPiloto: true,
        }));
    }

};

const { data: dataLicencia, } = useGetCompetidorByIdQuery(newRecord.idPiloto);



React.useEffect(() => {
    const fetchLicencia = async () => {
        try {
            if (newRecord.idPiloto) {
                const result = await dataLicencia;
                if (result) {
                    const { idCompetidor, nombre } = result.competidorIndividual;
                    setNewRecord(prevRecord => {
                        return {
                            ...prevRecord,
                            idPiloto: idCompetidor
                        };
                    });
                    setPiloto(nombre)
                }
            }
        } catch (error) {
            console.error("Error obteniendo datos de la licencia:", error);
            toast.error("El competidor no se encuentra cargado!");
            return;
        }
    };

    if (newRecord.idPiloto) {
        fetchLicencia();
    }

}, [dataLicencia]);

const actualizarPosicion = (e) => {
    const { value } = e.target;
    setNewRecord(prevRecord => {
        return {
            ...prevRecord,
            idPiloto: value
        };
    });
}

const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === 'provincia') {
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: newRecord?.territorio?.trim() === 'Provincial' && newRecord?.provincia?.trim() === "",
        }));
    } else if (name === 'tipo') {

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: newRecord?.territorio?.trim() === 'Nacional' && newRecord?.tipo?.trim() === "",
        }));
    } else {
        // Validar y actualizar el estado de error al abandonar el campo
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === '', // Marcar como error si el valor está vacío
        }));
    }

};

const handleChange = (e) => {
    const { name, value } = e.target;
    setNewRecord({
        ...newRecord,
        [name]: value,
    });

    if (name === 'provincia') {
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            provincia: newRecord?.territorio?.trim() === 'Provincial' && newRecord?.provincia?.trim() === "",

        }));
    } else if (name === 'tipo') {

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            tipo: newRecord?.territorio?.trim() === 'Nacional' && newRecord?.tipo?.trim() === "",
            provincia: false
        }));
    } else if (name === 'idCategoria') {
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: newRecord?.idCategoria === 0,
        }));
    } else {
        // Validar y actualizar el estado de error al abandonar el campo
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === '', // Marcar como error si el valor está vacío
        }));
    }

};

const handleChangeTerritorio = (event) => {
    setNewRecord({ ...newRecord, territorio: event.target.value }); // Actualizar el estado del territorio
};


const handleRequired = () => {

    const errors = {
        // piloto: newRecord?.idPiloto?.trim() === "",
        territorio: newRecord?.territorio?.trim() === "",
        despegue: newRecord?.despegue?.trim() === "",
        provincia: newRecord?.territorio?.trim() === 'Provincial' && newRecord?.provincia?.trim() === "",
        tipo: newRecord?.territorio?.trim() === 'Nacional' && newRecord?.tipo?.trim() === "",
        track: newRecord?.track?.trim() === "",
        marca: newRecord?.marca?.trim() === "",

        // modificador: newRecord.modificador === 0 || isNaN(newRecord.modificador)
    };

    setValidationErrors(errors);

    // Verificar si hay algún error de validación
    if (Object.values(errors).some(error => error)) {

        console.log("No se puede guardar debido a errores de validación");
        return true; // Indicar que hay errores
    }

    return false; // No hay errores
};

const [modificarRecord, error] = useModificarRecordMutation();

const handleEditar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
        toast.error("Todos los datos son obligatorios!");
        return; // Detener la función si hay errores
    }

    //Enviar un POST a la api
    try {
        console.log(newRecord);
        const modId = await modificarRecord(newRecord);

        if (modId.error) throw new Error(error)
        // setNewRecord();
        toast.success('Record modificado!', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'success3',
        });
        window.location.reload();
    } catch (error) {
        toast.error('Error modificando  Record!', {
            position: toast.POSITION.TOP_CENTER
        });
        console.error("Error modificando  Record:", error);
    }


}
const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false); // Estado para controlar la visibilidad del modal
const handleDeleteConfirmModalOpen = (event) => { event.stopPropagation(); setShowDeleteConfirmModal(true); }
const handleDeleteConfirmModalClose = (event) => { event.stopPropagation(); setShowDeleteConfirmModal(false); }

const [borrarRecordById,] = useBorrarRecordByIdMutation();

const handleEliminar = async (e) => {
    e.preventDefault();
    if (handleRequired()) {
        toast.error("Todos los datos son obligatorios!");
        return; // Detener la función si hay errores
    }

    handleDeleteConfirmModalOpen(e);

}

const confirmarEliminar = async (e) => {
    handleDeleteConfirmModalClose(e);
    //Enviar un POST a la api
    try {

        const modId = await borrarRecordById(newRecord?.id);
        if (modId.error) throw new Error(error)
        // setNewRecord();
        toast.success('Record eliminado!', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'success3',
        });
        window.location.reload();
    } catch (error) {
        toast.error('Error eliminando  Record!', {
            position: toast.POSITION.TOP_CENTER
        });
        console.error("Error eliminando  Record:", error);
    }
    handleClose()
}

const cancelarEliminar = (e) => {
    // Ocultar el modal de confirmación
    handleDeleteConfirmModalClose(e);
    handleClose()
}


const [tiposRecords, setTiposRecords] = useState([])
const { data: dataTiposRecords } = useGetListaTiposRecordsQuery()
useEffect(() => {
    if (!dataTiposRecords) return;
    const tipodistancialibre = dataTiposRecords?.listaTipoRecord
        ?.filter(tipo => tipo.id === 3);

    const otrostipos = dataTiposRecords?.listaTipoRecord
        ?.filter((tipo) => tipo.activo !== false)
        .filter(tipo => tipo.id !== 3);

    const filtrado = [...tipodistancialibre, ...otrostipos];
    setTiposRecords(filtrado);

    return () => {
        setTiposRecords([])

    }
}, [dataTiposRecords])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <>
          <Grid container>
            <Grid item className="GridItemCN" style={{ marginTop: "5%" }}>
              <Typography
                className="Titulo TituloPpalCN"
                sx={{ textAlign: "center" }}
              >
                {accion} Record
              </Typography>
            </Grid>

            <Divider className="DividerCN" />
            <Grid item className="GridItemCN" sx={{ px: 1 }} xs={11.8}>
              <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Territorio
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="territorio"
                    value={newRecord ? newRecord.territorio : ""}
                    label="Territorio"
                    onChange={handleChangeTerritorio}
                    required
                    disabled={accion === "Eliminar"}
                  >
                    <MenuItem value={"Nacional"}>Nacional</MenuItem>
                    <MenuItem value={"Provincial"}>Provincial</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
                {newRecord?.territorio === "Provincial" ? (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Provincia
                    </InputLabel>
                    <Select
                      sx={{ mx: 1 }}
                      label="Provincia"
                      name="provincia"
                      value={newRecord ? newRecord.provincia : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      fullWidth
                      disabled={accion === "Eliminar"}
                      error={validationErrors.provincia}
                      helperText={
                        validationErrors.provincia && "Este campo es requerido"
                      }
                    >
                      <MenuItem value={"Catamarca"}>Catamarca</MenuItem>
                      <MenuItem value={"Córdoba"}>Córdoba</MenuItem>
                      <MenuItem value={"Buenos Aires"}>Buenos Aires</MenuItem>
                      <MenuItem value={"Corrientes"}>Corrientes</MenuItem>
                      <MenuItem value={"Chaco"}>Chaco</MenuItem>
                      <MenuItem value={"Chubut"}>Chubut</MenuItem>
                      <MenuItem value={"Entre Ríos"}>Entre Ríos</MenuItem>
                      <MenuItem value={"Formosa"}>Formosa</MenuItem>
                      <MenuItem value={"Jujuy"}>Jujuy</MenuItem>
                      <MenuItem value={"La Pampa"}>La Pampa</MenuItem>
                      <MenuItem value={"La Rioja"}>La Rioja</MenuItem>
                      <MenuItem value={"Mendoza"}>Mendoza</MenuItem>
                      <MenuItem value={"Misiones"}>Misiones</MenuItem>
                      <MenuItem value={"Neuquén"}>Neuquén</MenuItem>
                      <MenuItem value={"Río Negro"}>Río Negro</MenuItem>
                      <MenuItem value={"Salta"}>Salta</MenuItem>
                      <MenuItem value={"San Juan"}>San Juan</MenuItem>
                      <MenuItem value={"San Luis"}>San Luis</MenuItem>
                      <MenuItem value={"Santa Cruz"}>Santa Cruz</MenuItem>
                      <MenuItem value={"Santa Fé"}>Santa Fé</MenuItem>
                      <MenuItem value={"Santiago del Estero"}>
                        Santiago del Estero
                      </MenuItem>
                      <MenuItem value={"Tierra del Fuego"}>
                        Tierra del Fuego
                      </MenuItem>
                      <MenuItem value={"Tucumán"}>Tucumán</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Box sx={{ minWidth: "100%", maxWidth: "100%" }}></Box>
                )}
              </Box>
            </Grid>
            <Divider className="DividerCN" sx={{ width: "100%" }} />

            <Grid item className="GridItemCN" xs={11}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                  sx={{}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="tipo"
                  value={newRecord ? newRecord.tipo.trim() : ""}
                  label="Tipo"
                  onChange={handleChange}
                  required
                  disabled={accion === "Eliminar"}
                >
                  {tiposRecords?.length > 0 ? (
                    tiposRecords.map((item) => (
                      <MenuItem key={item.id} value={item.nombre.trim()}>
                        {item.nombre.trim()}
                      </MenuItem>
                    ))
                  ) : (
                    <>
                      <MenuItem value={"Distancia Libre"}>
                        Distancia Libre
                      </MenuItem>
                      <MenuItem value={"Distancia en Ida y Vuelta"}>
                        Distancia en Ida y Vuelta
                      </MenuItem>
                      <MenuItem value={"Distancia en Triángulo Libre"}>
                        Distancia en Triángulo Libre
                      </MenuItem>
                      <MenuItem value={"Distancia en Triángulo FAI"}>
                        Distancia en Triángulo FAI
                      </MenuItem>
                      <MenuItem value={"Velocidad sobre Triángulo FAI 25km"}>
                        Velocidad sobre Triángulo FAI 25km
                      </MenuItem>
                      <MenuItem value={"Velocidad sobre Triángulo FAI 50km"}>
                        Velocidad sobre Triángulo FAI 50km
                      </MenuItem>
                      <MenuItem value={"Velocidad sobre Triángulo FAI 100km"}>
                        Velocidad sobre Triángulo FAI 100km
                      </MenuItem>
                      <MenuItem value={"Velocidad sobre Triángulo FAI 200km"}>
                        Velocidad sobre Triángulo FAI 200km
                      </MenuItem>
                      <MenuItem value={"Velocidad en Ida y Vuelta de 50km"}>
                        Velocidad en Ida y Vuelta de 50km
                      </MenuItem>
                      <MenuItem value={"Velocidad en Ida y Vuelta de 100km"}>
                        Velocidad en Ida y Vuelta de 100km
                      </MenuItem>
                      <MenuItem value={"Velocidad en Ida y Vuelta de 200km"}>
                        Velocidad en Ida y Vuelta de 200km
                      </MenuItem>
                      <MenuItem value={"Velocidad en Ida y Vuelta de 300km"}>
                        Velocidad en Ida y Vuelta de 300km
                      </MenuItem>
                    </>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item className="GridItemCN" xs={11}>
              {resCategorias ? (
                <FormControl
                  fullWidth
                  sx={{ textAlign: "center" }}
                  error={hasError.categoria}
                >
                  <InputLabel id="demo-simple-select-label">
                    Categoria
                  </InputLabel>
                  <Select
                    sx={{ mx: 1 }}
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idCategoria"
                    value={newRecord ? newRecord.idCategoria : ""}
                    label="Categoría"
                    onChange={handleChange}
                    required
                    disabled={accion === "Eliminar"}
                  >
                    {dataCategorias?.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {hasError.categoria && (
                    <FormHelperText
                      sx={{ color: "#D32F2F", textAlign: "center" }}
                    >
                      Campo requerido!
                    </FormHelperText>
                  )}
                </FormControl>
              ) : isLoadingCategorias ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ width: "50%", height: "50%", textAlign: "center" }}
                  >
                    <CircularProgress
                      style={{ width: "30px", height: "30px" }}
                    />
                    <Typography
                      style={{
                        color: "rgb(0, 150, 215)",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      Cargando Categoría
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography className="Titulo">
                  {" No hay Categoría para mostrar."}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              className="GridItemCN"
              xs={11}
              style={{ marginTop: "10px" }}
            >
              <Box
                sx={{ minWidth: "50%", maxWidth: "50%" }}
                style={{ paddingRight: "18px" }}
              >
                <TextField
                  sx={{ mx: 1 }}
                  label="Licencia"
                  name="licencia"
                  value={newRecord ? newRecord.idPiloto : null}
                  onBlur={(e) => handleBlurLicencia(e)}
                  onChange={(e) => handleChangeLicencia(e)}
                  required
                  fullWidth
                  disabled={accion === "Eliminar"}
                  error={validationErrors.idPiloto}
                  helperText={
                    validationErrors.idPiloto && "Este campo es requerido"
                  }
                />
              </Box>
              <Box sx={{ minWidth: "50%", maxWidth: "50%" }}>
                <TextField
                  sx={{ mx: 1 }}
                  label="Nombre y Apellido"
                  name="nombre"
                  value={piloto}
                  onChange={(e) => actualizarPosicion(e)}
                  required
                  fullWidth
                  disabled
                />
              </Box>
            </Grid>

            <Grid
              item
              className="GridItemCN"
              xs={12}
              style={{ marginTop: "10px" }}
            >
              <TextField
                sx={{ mx: 1 }}
                label="Track"
                name="track"
                value={newRecord ? newRecord.track : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                disabled={accion === "Eliminar"}
                error={validationErrors.track}
                helperText={validationErrors.track && "Este campo es requerido"}
              />
            </Grid>

            <Grid
              item
              className="GridItemCN"
              xs={11.8}
              style={{ marginTop: "10px" }}
            >
              <TextField
                sx={{ px: 1, mx: 0 }}
                label="Ciudad/Despegue"
                name="despegue"
                value={newRecord ? newRecord.despegue : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                disabled={accion === "Eliminar"}
                error={validationErrors.despegue}
                helperText={
                  validationErrors.despegue && "Este campo es requerido"
                }
              />
            </Grid>
            <Divider className="DividerCN" />

            <Grid item className="GridItemCN" xs={11.9}>
              <TextField
                sx={{ mx: 1 }}
                label="Marca"
                name="marca"
                value={newRecord ? newRecord.marca : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                disabled={accion === "Eliminar"}
                error={validationErrors.marca}
                helperText={validationErrors.marca && "Este campo es requerido"}
              />
            </Grid>

            <Grid
              item
              className="GridItemCN"
              xs={12}
              style={{ marginTop: "10px" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                firstDayOfWeek={0}
              >
                <DatePicker
                  value={
                    dayjs(newRecord?.fecha, { format: "YYYY-MM-DD" }).isValid()
                      ? dayjs(newRecord?.fecha, { format: "YYYY-MM-DD" })
                      : dayjs()
                  }
                  label="Fecha de Vuelo"
                  name="fecha"
                  disableTextEditor
                  disabled={accion === "Eliminar"}
                  inputFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  onChange={(newValue) => {
                    const fecha = dayjs(newValue).format("YYYY-MM-DD");
                    try {
                      setNewRecord((prevNewData) => ({
                        ...prevNewData,
                        fecha: fecha,
                      }));
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  sx={{ mx: 1, width: "100%", marginRight: "2%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              className="GridItemCN"
              xs={12}
              style={{ marginTop: "10px" }}
            >
              <Box sx={{ mx: 1, width: "100%", marginRight: "2%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="estado"
                    value={newRecord.estado ? newRecord.estado : " "}
                    label="Estado"
                    onChange={handleChange}
                    disabled={accion === "Eliminar"}
                    required
                  >
                    <MenuItem value={" "}>--Seleccione Estado--</MenuItem>
                    <MenuItem value={"Vigente"}>Vigente</MenuItem>
                    <MenuItem value={"Superado"}>Superado</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Divider className="DividerCN" />
          </Grid>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleButtonAction} color="primary">
          {accion}
        </Button>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
      {showDeleteConfirmModal && (
        <>
          <Dialog
            fullWidth
            open={showDeleteConfirmModal}
            onClose={cancelarEliminar}
          >
            <Box sx={{ border: "1px solid #C0C0C0", m: 1 }}>
              <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                Confirmación
              </DialogTitle>

              <DialogContent>
                <p>¿Estás seguro de que deseas eliminar este registro?</p>
              </DialogContent>

              <DialogActions>
                <Button variant="contained" onClick={confirmarEliminar}>
                  Confirmar
                </Button>
                <Button variant="outlined" onClick={cancelarEliminar}>
                  Cancelar
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </>
      )}
    </Dialog>
  );
};

export default ModalEditarEliminarRecord;
