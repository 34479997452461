import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import CrearCategoria from "./componentes/CrearCategoria";
import ListaCategoria from "./componentes/ListaCategoria";
import { MenuContext } from "../../../../context/MenuContext";
import { useGetListaCategoriasQuery } from "../../../../redux/services/categoria";

const Categoria = () => {
  const [categoria, setCategoria] = React.useState([]);
  const { menuState } = useContext(MenuContext);
  const { data: dataCategorias, isLoading: isLoadingCategoria } =
    useGetListaCategoriasQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataCategorias?.listaCategorias?.filter(
          (tipo) => tipo.activo !== false
        );
        setCategoria(filtrado);
      } catch (error) {
        console.error("Error al obtener lista categorias:", error);
      }
    };
    fetchData();
  }, [dataCategorias]);

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {categoria ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Categoría:
                  </Typography>
                  {categoria?.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay Categoría para mostrar.
                    </Typography>
                  ) : (
                    categoria.map((tipo) => (
                      <ListaCategoria
                        key={tipo.id}
                        Tipo={tipo}
                        Origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : isLoadingCategoria ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay categoria para mostrar."}
                </Typography>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />
              <Box style={{ width: "100%" }} className="">
                <CrearCategoria sinmenu={true} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Categoria;
