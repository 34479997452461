/* eslint-disable eqeqeq */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import { useGetCompetidorByIdQuery } from "../../../../../redux/services/competidor";
import { useAgregarHistorialMutation } from "../../../../../redux/services/historial";
import { useGetListaModalidadesSelectQuery } from "../../../../../redux/services/modalidad";

const CrearHistorial = ({ sinmenu }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    posiciones: false,
    desde: false,
    hasta: false,
    licencia: [false, false, false],
  });

  const initialState = {
    posiciones: Array.from({ length: 3 }, () => ({
      id: 0,
      nombre: "",
      ciudad: "",
      provincia: "",
    })),
    desde: null,
    hasta: null,
    idModalidadFk: 0,
    activo: true,
    track: "",
    pdf: null,
    nombrePdf: "",
  };

  const [temporada, setTemporada] = React.useState(initialState);
  const [legajo, setLegajo] = React.useState();
  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  const [dataModalidades, setDataModalidades] = React.useState();
  const { data: resModalidades, isLoading: isLoadingModalidad } =
    useGetListaModalidadesSelectQuery();

  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  const actualizarPosicion = (e, index) => {
    const { name, value } = e.target;
    setTemporada((prevState) => {
      const nuevasPosiciones = [...prevState.posiciones];
      nuevasPosiciones[index] = {
        ...nuevasPosiciones[index],
        [name]: value,
      };
      return { ...prevState, posiciones: nuevasPosiciones };
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "desde" || name === "hasta") {
      errorMessage =
        value === "" || value.trim() === "" || isNaN(value) || value == 0
          ? "Este campo es requerido"
          : "";
      if (
        name === "desde" &&
        temporada.hasta &&
        parseInt(value) > parseInt(temporada.hasta)
      ) {
        errorMessage = "El año desde debe ser menor que el año hasta";
      } else if (
        name === "hasta" &&
        temporada.desde &&
        parseInt(value) < parseInt(temporada.desde)
      ) {
        errorMessage = "El año hasta debe ser mayor que el año desde";
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const isValidYear = /^\d{4}$/.test(value) && ["1", "2"].includes(value[0]);
    let errorMessage = "";

    if (name === "desde" || name === "hasta") {
      if (!value.trim()) {
        errorMessage = "Este campo es requerido";
      } else if (!isValidYear) {
        errorMessage = "El número debe comenzar con 1 o 2 y contener 4 dígitos";
      } else if (
        name === "desde" &&
        temporada.hasta &&
        parseInt(value) > parseInt(temporada.hasta)
      ) {
        errorMessage = "El año desde debe ser menor que el año hasta";
      } else if (
        name === "hasta" &&
        temporada.desde &&
        parseInt(value) < parseInt(temporada.desde)
      ) {
        errorMessage = "El año hasta debe ser mayor que el año desde";
      }
    }
    setTemporada({
      ...temporada,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const handleChangeLicencia = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    setTemporada((prevState) => {
      let newObjet = {
        ...prevState,
      };
      newObjet.posiciones[index] = value;
      return {
        ...newObjet,
      };
    });
  };

  const handleBlurLicencia = (e, index) => {
    e.preventDefault();
    const { value } = e.target;

    let errorMessage = "";

    if (value.trim() === "" || isNaN(value) || parseFloat(value) <= 0) {
      errorMessage = "Este campo es obligatorio";
      setValidationErrors((prevErrors) => {
        let newOBjet = {
          ...prevErrors,
        };
        newOBjet.licencia[index] = true;
        return { ...newOBjet };
      });
    } else {
      setValidationErrors((prevErrors) => {
        let newOBjet = {
          ...prevErrors,
        };
        newOBjet.licencia[index] = false;
        return { ...newOBjet };
      });
    }

    if (errorMessage === "") {
      setLegajo({
        ...legajo,
        index,
        id: value,
      });
    }
  };

  const { data: dataLicencia } = useGetCompetidorByIdQuery(legajo?.id);

  React.useEffect(() => {
    const fetchLicencia = async (index) => {
      try {
        if (legajo) {
          const result = await dataLicencia;
          if (result) {
            const { idCompetidor, nombre, ciudad, provincia } =
              result.competidorIndividual;
            setTemporada((prevTemporada) => {
              const nuevasPosiciones = [...prevTemporada.posiciones];
              nuevasPosiciones[index] = {
                id: idCompetidor,
                nombre: nombre,
                ciudad: ciudad,
                provincia: provincia,
              };
              return { ...prevTemporada, posiciones: nuevasPosiciones };
            });
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("No existe competidor con esa licencia!");
        return;
      }
    };

    if (legajo) {
      fetchLicencia(legajo.index);
    }
  }, [dataLicencia]);

  const hiddenInputRef = React.createRef();
  const [fileNameRules, setFileNameRules] = React.useState("");

  const handleOpenFileDialog = () => {
    hiddenInputRef.current.click();
  };
  const HiddenInput = styled("input")({
    display: "none",
  });

  const handleReglaChange = (e) => {
    const file = e.target.files[0];
    setFileNameRules(file?.name);
    if (file) {
      setTemporada({
        ...temporada,
        pdf: file,
        nombrePdf: file?.name,
      });
    }
  };

  const handleRequired = () => {
    const errors = {
      desde: temporada.desde == 0 || isNaN(temporada.desde),
      hasta: temporada.hasta == 0 || isNaN(temporada.hasta),
    };

    setValidationErrors((prevState) => {
      return {
        ...prevState,
        desde: temporada.desde == 0 || isNaN(temporada.desde),
        hasta: temporada.hasta == 0 || isNaN(temporada.hasta),
      };
    });

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [agregarHisorial, error] = useAgregarHistorialMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    let hasLicenciaErrors = false;

    temporada.posiciones.map((posicion, index) => {
      const id = parseFloat(posicion.id);
      if (isNaN(id) || id <= 0 || posicion.nombre === "") {
        hasLicenciaErrors = true;
        setValidationErrors((prevState) => {
          let newObjet = {
            ...prevState,
          };
          newObjet.licencia[index] = true;
          return {
            ...newObjet,
          };
        });
      } else {
        setValidationErrors((prevState) => {
          let newObjet = {
            ...prevState,
          };
          newObjet.licencia[index] = false;
          return {
            ...newObjet,
          };
        });
        hasLicenciaErrors = false;
      }
    });

    if (hasLicenciaErrors) {
      toast.error("Todos los datos de licencia son obligatorios");
      return;
    }

    if (handleRequired()) {
      toast.error("Año desde y hasta son obligatorios!");
      return;
    }

    if (temporada.idModalidadFk === 0) {
      setHasError({ ...hasError, modalidad: true });
      toast.error("Falta modalidad!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, modalidad: false });
    }
    if (temporada.idTipoCompetenciaFk === 0) {
      setHasError({ ...hasError, tipoCompetencia: true });
      toast.error("Falta tipo de competencia!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, tipoCompetencia: false });
    }

    try {
      const temporadaDesde = temporada.desde.startsWith("0")
        ? temporada.desde.substring(1)
        : temporada.desde;
      const temporadaHasta = temporada.hasta.startsWith("0")
        ? temporada.hasta.substring(1)
        : temporada.hasta;

      const Historial = {
        temporada: `${temporadaDesde}/${temporadaHasta}`,
        idModalidadFK: temporada.idModalidadFk,
        piloto1: temporada.posiciones[0].id,
        piloto2: temporada.posiciones[1].id,
        piloto3: temporada.posiciones[2].id,
        info: temporada.track,
        pdf: temporada.pdf,
        nombrePdf: temporada.nombrePdf,
      };
      const modId = await agregarHisorial(Historial);
      if (modId.error) throw new Error(error);
      setTemporada({ ...initialState, desde: "", hasta: "" });
      toast.success("Temporada creada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando la Temporada!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    } finally {
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ mt: { xs: "10%", sm: "5%" } }}>
        {!sinmenu && <BarraNavegacion />}

        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN" spacing={2}>
            <Grid item xs={12}>
              <Typography className="Titulo TituloPpalCN">
                Crear Temporada
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            {temporada.posiciones?.map((posicion, indice) => (
              <Fragment key={indice}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{indice + 1}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      sx={{ mx: { xs: 0, sm: 1 } }}
                      label="Licencia"
                      name="licencia"
                      value={posicion.id}
                      onBlur={(e) => handleBlurLicencia(e, indice)}
                      onChange={(e) => handleChangeLicencia(e, indice)}
                      required
                      fullWidth
                      error={validationErrors.licencia[indice]}
                      helperText={
                        validationErrors.licencia[indice] &&
                        "Este campo es requerido"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      sx={{ mx: { xs: 0, sm: 1 } }}
                      label="Nombre y Apellido"
                      name="nombre"
                      value={posicion.nombre || ""}
                      onChange={(e, index) => actualizarPosicion(e, index)}
                      required
                      fullWidth
                      disabled
                      error={validationErrors.nombre}
                      helperText={
                        validationErrors.nombre && "Este campo es requerido"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      sx={{ mx: { xs: 0, sm: 1 } }}
                      label="Ciudad"
                      name="ciudad"
                      value={posicion.ciudad || ""}
                      onChange={actualizarPosicion}
                      disabled
                      required
                      fullWidth
                      error={validationErrors.ciudad}
                      helperText={
                        validationErrors.ciudad && "Este campo es requerido"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      sx={{ mx: { xs: 0, sm: 1 } }}
                      label="Provincia"
                      name="provincia"
                      value={posicion.provincia || ""}
                      onChange={actualizarPosicion}
                      disabled
                      required
                      fullWidth
                      error={validationErrors.provincia}
                      helperText={
                        validationErrors.provincia && "Este campo es requerido"
                      }
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ))}
            <Divider className="DividerCN" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ mx: 1 }}
                  label="Año desde"
                  type="number"
                  name="desde"
                  defaultValue={null}
                  value={temporada.desde}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    min: 1000,
                    max: 2999,
                    step: 1,
                  }}
                  required
                  fullWidth
                  error={validationErrors.desde}
                  helperText={
                    validationErrors.desde &&
                    "Este campo es requerido y debe tener formato de año"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ mx: 1 }}
                  label="Año hasta"
                  type="number"
                  name="hasta"
                  defaultValue={null}
                  value={temporada.hasta}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    min: 1000,
                    max: 2999,
                    step: 1,
                  }}
                  required
                  fullWidth
                  error={validationErrors.hasta}
                  helperText={
                    validationErrors.hasta &&
                    "Este campo es requerido, debe tener formato de año y mayor que el año desde"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Archivo .pdf, .doc, .docx"
                  name="reglasPDF"
                  value={fileNameRules || ""}
                  variant="outlined"
                  autoComplete="off"
                  onClick={handleOpenFileDialog}
                  InputProps={{ readOnly: true }}
                  sx={{ bgcolor: "transparent", mx: 1 }}
                  required
                  fullWidth
                />
                <HiddenInput
                  name="reglasPDF"
                  type="file"
                  ref={hiddenInputRef}
                  accept=".pdf, .doc, .docx"
                  onChange={handleReglaChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ mx: 1 }}
                  label="Link"
                  name="track"
                  value={temporada.track}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.track}
                  helperText={
                    validationErrors.track && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6}>
                {resModalidades ? (
                  <FormControl
                    fullWidth
                    sx={{ textAlign: "center" }}
                    error={hasError.modalidad}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Modalidad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idModalidadFk"
                      value={temporada.idModalidadFk}
                      label="Modalidad"
                      onChange={handleChange}
                      required
                    >
                      {dataModalidades?.map((modalidad) => (
                        <MenuItem key={modalidad.id} value={modalidad.id}>
                          {modalidad.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    {hasError.modalidad && (
                      <FormHelperText
                        sx={{ color: "#D32F2F", textAlign: "center" }}
                      >
                        Campo requerido!
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : isLoadingModalidad ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <CircularProgress
                        style={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        style={{
                          color: "rgb(0, 150, 215)",
                          fontSize: "15px",
                          marginTop: "10px",
                        }}
                      >
                        Cargando Modalidad
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography className="Titulo">
                    {" No hay Modalidad para mostrar."}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item xs={12} className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CrearHistorial;
