import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const useConfirmationModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [confirmLabel, setConfirmLabel] = useState("Confirm");
    const [onConfirm, setOnConfirm] = useState(() => { });

    const openConfirm = (title, message, confirmLabel, onConfirm2) => {
        setTitle(title);
        setMessage(message);
        setConfirmLabel(confirmLabel);
        setIsOpen(true);
        setOnConfirm(() => onConfirm2);
    }
    const [fetching, setIsFetching] = useState(false);

    const handleConfirm = async () => {
        try {
            setIsFetching(true);
            await onConfirm();
            handleCancel(false);
        } catch (error) {
            console.log(error);
        } finally {
            setIsFetching(false)
        }
    };

    const handleCancel = () => {
        setTitle("");
        setMessage("");
        setConfirmLabel("Confirm");
        setOnConfirm(() => { });
        setIsOpen(false);
    };

    const ModalConfirmacion = () => (
        <Dialog fullWidth open={isOpen} onClose={handleCancel} >
            <Box sx={{ border: '1px solid #C0C0C0', m: 1 }} >
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>{title}</DialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={fetching} variant="contained" onClick={handleConfirm}>{confirmLabel}</LoadingButton>
                    <Button variant="outlined" onClick={handleCancel}>Cancelar</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

    return { openConfirm, ModalConfirmacion };
};

export default useConfirmationModal;