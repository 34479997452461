import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FAVL_Logo from "../static/img/FAVL-logo1.png";
import "./Styles/MenuLateral.css";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { MenuContext } from "../context/MenuContext";
import { useDispatch } from "react-redux";
import { logout } from "../redux/features/authSlice";

function MenuLateral({ opcMenu, menuTitulo, typeMenu }) {
  const { menuState, setMenuState } = useContext(MenuContext);
  const [menuMobileState, setMenuMobileState] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const [open, setOpen] = useState(false);
  //let navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (isMobile) {
      setMenuState(false);
      setMenuMobileState(false);
    }

    return () => {
      setMenuState(true);
      setMenuMobileState(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = (action) => {
    if (isMobile) {
      setMenuMobileState(false);
      window.scrollTo(0, 0);
    }
    if (action) {
      action();
    }
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{
          position: "fixed",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgb(246, 240, 240)",
          boxShadow: "0px 0px 30px rgba(150, 150, 150 , 0.5)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "65px",
        }}
      >
        <Toolbar>
          <Grid container className="gridAppBar">
            {isMobile ? (
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    setMenuMobileState(!menuMobileState);
                    window.scrollTo(0, 0);
                  }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Grid>
            ) : !menuState ? (
              <Grid item xs={2}>
                <IconButton onClick={() => setMenuState(!menuState)}>
                  <ArrowForwardIosOutlinedIcon
                    fontSize="small"
                    color="primary"
                  />
                </IconButton>
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}
            <Grid item xs={8} className="gridItem gridCategoria">
              <Typography className="AppBarTitulo">
                {menuTitulo?.toUpperCase()}
              </Typography>
              <Typography className="AppBarSubtitulo">{""}</Typography>
            </Grid>
            <Grid item xs={2} className="gridItem">
              <img
                title="Logo"
                src={FAVL_Logo}
                aria-label="logo favl"
                className="gridImagen"
              ></img>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {!isMobile ? (
        <Drawer
          variant="persistent"
          open={menuState}
          className="drawer"
          classes={{ paper: "drawerPapper" }}
        >
          <List className="Menu">
            {opcMenu?.map((opcion) => (
              <ListItem key={opcion.opcion} sx={{ p: 1 }}>
                <Button
                  className="botonMenu"
                  component={Link}
                  to={opcion.link}
                  onClick={() => handleMenuClick(opcion.action)}
                  state={opcion.state}
                >
                  {opcion.opcion}
                </Button>
              </ListItem>
            ))}
            <Divider sx={{ width: "90%" }} />
            <ListItem sx={{ p: 1 }}>
              <Button
                component={Link}
                onClick={() => handleMenuClick(handleClick)}
                to={"/"}
                className="botonMenu2"
              >
                Volver
              </Button>
            </ListItem>

            {typeMenu ? (
              <ListItem sx={{ p: 1 }}>
                <Button
                  component={Link}
                  onClick={handleLogout}
                  className="botonSesion"
                >
                  Cerrar Sesión
                </Button>
              </ListItem>
            ) : (
              <ListItem sx={{ p: 1 }}></ListItem>
            )}
          </List>
          <Divider />
          <Box className="closeButtonContainer">
            <IconButton onClick={() => setMenuState(!menuState)}>
              <ArrowBackIosOutlinedIcon color="action" fontSize="small" />
            </IconButton>
          </Box>
        </Drawer>
      ) : (
        menuMobileState && (
          <Box sx={{ mt: "65px" }}>
            <List className="Menu" style={{ background: "#fff" }}>
              {opcMenu?.map((opcion) => (
                <ListItem key={opcion.opcion} sx={{ p: 1 }}>
                  <Button
                    className="botonMenu"
                    component={Link}
                    to={opcion.link}
                    state={opcion.state}
                    onClick={() => handleMenuClick(opcion.action)}
                    sx={{ justifyContent: "center !important" }}
                  >
                    {opcion.opcion}
                  </Button>
                </ListItem>
              ))}
              <Divider sx={{ width: "90%" }} />
              <ListItem sx={{ p: 1 }}>
                <Button
                  component={Link}
                  onClick={() => handleMenuClick(handleClick)}
                  to={"/"}
                  className="botonMenu"
                >
                  Volver
                </Button>
              </ListItem>
              <ListItem sx={{ p: 1 }}>
                <Button
                  component={Link}
                  onClick={handleLogout}
                  className="botonSesion"
                  sx={{ justifyContent: "center !important" }}
                >
                  Cerrar Sesión
                </Button>
              </ListItem>
            </List>
          </Box>
        )
      )}
      <Box sx={{ mt: isMobile ? "65px" : "0px" }}></Box>
    </React.Fragment>
  );
}

export default MenuLateral;
