import React, { useContext, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ListaClub from "./componentes/ListaClub";
import CrearClub from "./componentes/CrearClub";
import { useGetListaClubQuery } from "../../../../redux/services/club";

const Club = () => {
  const { menuState } = useContext(MenuContext);
  const [club, setClub] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data: dataClub, isLoading: isLoadingClub } = useGetListaClubQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataClub?.listaClub;
        setClub(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataClub]);
  const totalPages = Math.ceil(club?.length / pageSize);

  const paginatedClub = club?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
      >
        <Grid container style={{ flexDirection: "row" }}>
          <Grid
            item
            xs={12}
            style={{ flexDirection: "column" }}
            className="gridColumnaPXC"
          >
            {club ? (
              <Box className="boxCalendarioPXC">
                <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                  Club:
                </Typography>

                {paginatedClub?.length === 0 ? (
                  <Typography
                    className="Titulo"
                    style={{ fontSize: 14, alignSelf: "center" }}
                  >
                    No hay Clubes para mostrar.
                  </Typography>
                ) : (
                  paginatedClub.map((club) => (
                    <ListaClub key={club.id} club={club} Origen={"auth"} />
                  ))
                )}
              </Box>
            ) : isLoadingClub ? (
              <CircularProgress />
            ) : (
              <Typography className="Titulo">
                {" No hay Clubes para mostrar."}
              </Typography>
            )}
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                {"<<"}
              </Button>
              <Typography
                variant="body1"
                style={{ margin: "5px 10px 0px 10px" }}
              >
                Pag.{currentPage} / {totalPages}
              </Typography>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {">>"}
              </Button>
            </Box>
            <Divider sx={{ width: "100%", m: 2 }} />

            <Box style={{ width: "100%" }} className="">
              <CrearClub />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Club;
