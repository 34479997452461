import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./styles/globalStyles.css";
import { StyledEngineProvider } from "@mui/material/styles";
import Home from "./routes/Home";

import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MenuContextProvider from "./context/MenuContext.js";
import ModalidadContextProvider from "./context/ModalidadContext.js";
import { store } from './redux/store.js';
import Acercade from "./routes/Acercade.js";
import Login from "./routes/admin/Login.js";
import Cms from "./routes/cms/Cms.js";
import Categoria from "./routes/cms/pages/categoria/Categoria.js";
import Club from "./routes/cms/pages/club/Club.js";
import Competencias from "./routes/cms/pages/Competencias";
import Historial from "./routes/cms/pages/historial/Historial.js";
import HomeCms from "./routes/cms/pages/HomeCms.js";
import Novedades from "./routes/cms/pages/Novedades";
import Pilotos from "./routes/cms/pages/pilotos/Pilotos.js";
import MangasDetalleAdmin from "./routes/cms/pages/ranking/componentes/MangasDetalleAdmin.js";
import RankingAdmin from "./routes/cms/pages/ranking/RankingAdmin.js";
import Record from "./routes/cms/pages/record/Record.js";
import ResultadosMangasDetalle from "./routes/cms/pages/resultados/componentes/ResultadosMangasDetalle.js";
import SubirMangasManual from "./routes/cms/pages/resultados/componentes/SubirMangasManual.js";
import SubirResultadosHtml from "./routes/cms/pages/resultados/componentes/SubirResultadosHtml.js";
import VerResultadosHtml from "./routes/cms/pages/resultados/componentes/VerResultadosHtml.js";
import Resultados from "./routes/cms/pages/resultados/Resultados.js";
import Seleccion from "./routes/cms/pages/seleccion/Seleccion.js";
import TipoCompetencias from "./routes/cms/pages/tipoCompetencia/TipoCompetencias.js";
import TipoRecord from "./routes/cms/pages/tipoRecord/TipoRecord.js";
import Construccion from "./routes/Construccion.js";
import Contacto from "./routes/Contacto.js";
import CrearCompetencia from "./routes/CrearCompetencia.js";
import CrearNovedad from "./routes/CrearNovedad";
import Inicio from "./routes/Inicio";
import ModalidadCnd from "./routes/ModalidadCnd.js";
import ParapenteXC from "./routes/ParapenteXC";
import HistorialPublico from "./routes/publico/pages/HistorialPublico.js";
import NovedadesCompetencias from "./routes/publico/pages/novedadesycompetencias/NovedadesCompetencias.js";
import MangasDetalle from "./routes/publico/pages/ranking/MangasDetalle.js";
import Ranking from "./routes/publico/pages/ranking/Ranking.js";
import RecordPublico from "./routes/publico/pages/record/RecordPublico.js";
import VerResultadosPublicados from "./routes/publico/pages/resultados/componentes/VerResultadosPublicados.js";
import ResultadosPublicados from "./routes/publico/pages/resultados/ResultadosPublicados.js";
import SeleccionPublicada from "./routes/publico/pages/seleccion/SeleccionPublicada.js";
import SeleccionInicio from "./routes/SeleccionInicio.js";
import ReglamentosPublico from "./routes/publico/pages/reglamentos/ReglamentosPublico.js";
import ReglamentosModalidades from "./routes/cms/pages/reglamentos/ReglamentosModalidades.js";
import Ajustes from "./routes/cms/pages/ajustes/Ajustes.js";
import ProtectedRoute from "./routes/admin/ProtectedRoute.js";
import Modalidades from "./routes/cms/pages/modalidades/Modalidades.js";
import ErrorPage from "./routes/ErrorPage.js";
import HistorialRanking from "./routes/publico/pages/HistorialRanking.js";

<ProtectedRoute />

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/modalidad/*",
    element: <ParapenteXC />,
  },
  {
    path: "/ModalidadCnd",
    element: <ModalidadCnd />,
  },
  {
    path: "/modalidad/Cnd",
    element: <Acercade />,
  },
  {
    path: "/ReglamentoCnd",
    element: <ReglamentosPublico />,
  },
  {
    path: "/Historial-Publico",
    element: <HistorialPublico />,
  },
  {
    path: "/HistorialRanking",
    element: <HistorialRanking />,
  },
  {
    path: "/Inicio",
    element: <Inicio />,
  },
  {
    path: "/SeleccionInicio",
    element: <SeleccionInicio />,
  },
  {
    path: "/NovedadesAnteriores",
    element: <NovedadesCompetencias />,
  },
  {
    path: "/CrearNovedad",
    element: <CrearNovedad />,
  },
  {
    path: "/CrearCompetencia",
    element: <CrearCompetencia />,
  },
  {
    path: "/Contacto",
    element: <Contacto />,
  },
  {
    path: "/Construccion",
    element: <Construccion />,
  },
  {
    path: "/Reglamento",
    element: <ReglamentosPublico />,
  },
  {
    path: "/Cms",
    element: (
      <ProtectedRoute>
        <Cms>
          <HomeCms />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Modalidades",
    // element: <Cms><Modalidades /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Modalidades />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Ajustes",
    //element: <Cms><Ajustes /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Ajustes />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/ReglamentosModalidades",
    //element: <Cms nombre="de Reglamentos por Modalidad"><ReglamentosModalidades /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms nombre="de Reglamentos por Modalidad">
          <ReglamentosModalidades />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Novedades",
    //element: <Cms> <Novedades /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Novedades />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Competencias",
    //element: <Cms><Competencias /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Competencias />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Seleccion",
    //element: <Cms><Seleccion /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Seleccion />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Tipo de Competencias",
    //element: <Cms><TipoCompetencias /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <TipoCompetencias />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Categoria",
    //element: <Cms><Categoria /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Categoria />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/TipoRecord",
    //element: <Cms nombre="Tipo de Record"> <TipoRecord /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms nombre="Tipo de Record">
          <TipoRecord />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Record",
    //element: <Cms><Record /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Record />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/RankingAdmin",
    //element: <Cms nombre="Ranking Admin"><RankingAdmin /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms nombre="Ranking Admin">
          <RankingAdmin />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Competidores",
    //element: <Cms><Pilotos /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Pilotos />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Club",
    //element: <Cms><Club /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Club />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Historial",
    //element: <Cms><Historial /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Historial />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/Resultados",
    //element: <Cms><Resultados /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <Resultados />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/SubirResultados",
    //element: <Cms nombre="Subir Resultados"><SubirResultadosHtml /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms nombre="Subir Resultados">
          <SubirResultadosHtml />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/VerResultados",
    //element: <Cms nombre="Ver Resultados"><VerResultadosHtml /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms nombre="Ver Resultados">
          <VerResultadosHtml />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/SubirMangasManual",
    element: <SubirMangasManual />,
  },
  {
    path: "/ResultadosMangasDetalle",
    //element: <Cms><ResultadosMangasDetalle /></Cms>,
    element: (
      <ProtectedRoute>
        <Cms>
          <ResultadosMangasDetalle />
        </Cms>
      </ProtectedRoute>
    ),
  },
  {
    path: "/ResultadosPublicados",
    element: <ResultadosPublicados />,
  },
  {
    path: "/VerResultadosPublicados",
    element: <VerResultadosPublicados />
  },
  {
    path: "/Ranking",
    element: <Ranking />,
  },
  {
    path: "/MangasDetalle",
    element: <MangasDetalle />,
  },
  {
    path: "/MangasDetalleAdmin",
    element: <MangasDetalleAdmin />,
  },
  {
    path: "/SeleccionPublicada",
    element: <SeleccionPublicada />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/RecordPublico",
    element: <RecordPublico />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ModalidadContextProvider>
        <MenuContextProvider>
          <StyledEngineProvider injectFirst>
            <RouterProvider router={router} />
          </StyledEngineProvider>
        </MenuContextProvider>
      </ModalidadContextProvider>
    </Provider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

