import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import ListaTipoRecord from "./componentes/ListaTipoRecord";
import CrearTipoRecord from "./componentes/CrearTipoRecord";
import { useGetListaTiposRecordsQuery } from "../../../../redux/services/tipoRecord";

const TipoRecord = () => {
  const [tiposRecords, setTiposRecords] = React.useState([]);
  const { menuState } = useContext(MenuContext);
  const { data: dataTipoRecords, isLoading: isLoadingTipoRecord } =
    useGetListaTiposRecordsQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tipodistancialibre = dataTipoRecords?.listaTipoRecord?.filter(
          (tipo) => tipo.id === 3
        );

        const otrostipos = dataTipoRecords?.listaTipoRecord
          ?.filter((tipo) => tipo.activo !== false)
          .filter((tipo) => tipo.id !== 3);

        const filtrado = [...tipodistancialibre, ...otrostipos];
        setTiposRecords(filtrado);
      } catch (error) {
        console.error("Error al obtener lista tipo de Records:", error);
      }
    };

    fetchData();
  }, [dataTipoRecords]);

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {tiposRecords ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Tipos de Record:
                  </Typography>
                  {tiposRecords?.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay tipos de Records para mostrar.
                    </Typography>
                  ) : (
                    tiposRecords?.map((tipo) => (
                      <ListaTipoRecord
                        key={tipo.id}
                        Tipo={tipo}
                        Origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : isLoadingTipoRecord ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay tipos de Records para mostrar."}
                </Typography>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />
              <Box style={{ width: "100%" }} className="">
                <CrearTipoRecord sinmenu={true} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TipoRecord;
