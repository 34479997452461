import React, { useState } from 'react';
import './Styles/NoticiasInicio.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import Novedad from '../routes/Novedad';
import dayjs from 'dayjs';

function NoticiasInicio({ novedad, origen }) {

  const [modalState, setModalState] = useState(false);

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleCloseModal = () => {
    setModalState(false);
  };

  return (
    <React.Fragment>
      <Accordion className="Acordeon">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
          key={novedad.id}
        >
          <Typography
            className="TituloPXC"
            sx={{ width: "75%", my: "15px" }}
          >
            {novedad.titulo}
          </Typography>
          <Box>
            <Typography className="SubtituloPXC textoPequeñoNI">
              {novedad.autor}
            </Typography>
            <Typography
              className="TextoPXC textoMuyPequeñoNI"
              sx={{ width: "100%" }}
            >

              {dayjs(novedad.fechaPublicacion).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails key={novedad.id} className="AcordeonDetail">
          <Typography className="Texto truncate-text">
            {
              novedad.asunto
                && (novedad.asunto.toLowerCase() === 'null' || novedad.asunto.toLowerCase() === 'undefined')
                ? '' : novedad.asunto
            }
          </Typography>

          <Button
            size="small"
            className="BotonNI"
            onClick={handleOpenModal}
          >
            Ver mas
          </Button>
          {modalState &&
            <Novedad
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Novedad={novedad}
              Origen={origen}
            />}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default NoticiasInicio