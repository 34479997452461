import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ModalHistorial from "./ModalHistorial";

const ListaHistorial = ({ historial, Origen }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false);

  function transformarObjeto(objetoRecibido) {
    const posiciones = [];

    const [inicio, fin] = objetoRecibido.temporada.split("/").map(Number);

    for (let i = 1; i <= 3; i++) {
      const piloto = objetoRecibido[`piloto${i}`];
      posiciones.push({
        id: piloto.id,
        nombre: piloto.nombre,
        ciudad: piloto.ciudad,
        provincia: piloto.provincia,
      });
    }

    const initialState = {
      id: objetoRecibido.id,
      posiciones,
      desde: inicio,
      hasta: fin,
      idModalidadFk: objetoRecibido.idModalidadFK.id,
      modalidadNombre: objetoRecibido.idModalidadFK.nombre,
      activo: objetoRecibido.activo,
      track: objetoRecibido.info,
      pdf: objetoRecibido.pdf || null,
      nombrePdf: objetoRecibido.nombrePdf || null,
    };

    return initialState;
  }

  const resultado = transformarObjeto(historial);

  return (
    <React.Fragment>
      <Accordion
        className="Acordeon"
        expanded={isAccordionExpanded}
        onChange={(event, isExpanded) => setIsAccordionExpanded(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
        >
          <Typography className="Titulo" sx={{ width: "75%" }}>
            {resultado.desde === resultado.hasta
              ? `${resultado.desde}, ${
                  historial.idModalidadFK.nombre
                    ? historial.idModalidadFK.nombre
                    : ""
                }`
              : `${historial.temporada}, ${
                  historial.idModalidadFK.nombre
                    ? historial.idModalidadFK.nombre
                    : ""
                }`}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ width: "auto", height: "auto", m: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails className="AcordeonDetail ">
          <ModalHistorial
            historial={(historial = resultado)}
            Origen={Origen}
            onClose={() => setIsAccordionExpanded(false)}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ListaHistorial;
