import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import {
  useBorrarCompetidorByIdMutation,
  useModificarCompetidorMutation,
} from "../../../../../redux/services/competidor";
import noimage from "../../../../../static/img/sinfoto.png";
import { useGetListaClubQuery } from "../../../../../redux/services/club";

const ModalPiloto = ({ competidor, Origen = "readonly", onClose }) => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const [isEditable, setIsEditable] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [newData, setNewData] = React.useState(competidor);
  const formRef = React.useRef();

  React.useEffect(() => {
    setNewData(competidor);
  }, [competidor]);

  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
    ciudad: false,
    provincia: false,
    dni: false,
    idCompetidor: false,
  });
  const { openConfirm, ModalConfirmacion } = useConfirmationModal();
  const [modificarTipoCompetencia, error] = useModificarCompetidorMutation();

  const [dataClub, setDataClub] = React.useState();
  const { data: resClub, isLoading: isLoadingClub } = useGetListaClubQuery();
  const [hasError, setHasError] = React.useState({
    club: false,
  });

  React.useEffect(() => {
    try {
      setDataClub(resClub?.listaClub);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataClub();
    };
  }, [resClub]);

  const handleRequired = () => {
    const errors = {
      nombre: newData.nombre.trim() === "",
      ciudad: newData.ciudad === null || newData.ciudad.trim() === "",
      provincia: newData.provincia === null || newData.provincia.trim() === "",
      idCompetidor: newData.idCompetidor === 0 || isNaN(newData.idCompetidor),
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }
    return false;
  };
  const handleEdit = async () => {
    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }
    try {
      const result = await modificarTipoCompetencia(newData);
      if (result.error) throw new Error(error);
      handleCancelar();
      toast.success("Piloto actualizado con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Error Actualizando Piloto!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
  };

  const [borrarCompetidorById] = useBorrarCompetidorByIdMutation();

  const handleDelete = async () => {
    openConfirm(
      "Confirmación de Borrado",
      "¿Eliminar este competidor?",
      "Eliminar",
      async () => {
        try {
          const result = await borrarCompetidorById(competidor.idCompetidor);
          if (result.error) throw new Error(result.error);
          handleCancelar();
          toast.success("Piloto eliminado con éxito!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "success1",
          });
          window.location.reload();
        } catch (error) {
          toast.error("Error Borrando Piloto!", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(error);
        }
      }
    );
  };

  const handleCancelar = () => {
    setIsEditable(false);
    setIsDelete(false);
    onClose();
    setNewData(competidor);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "idCompetidor")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value == 0 || isNaN(value),
      }));
    else
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let errorMessage = "";

    if (name === "idCompetidor")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value == 0 || isNaN(value),
      }));

    setNewData({
      ...newData,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const [fileName, setFileName] = useState("");
  const [previewImgPilotoUrl, setPreviewImgPilotoUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setNewData({
        ...newData,
        foto: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgPilotoUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Box key={competidor.idCompetidor} style={{ width: "100%" }}>
        <Box>
          <form ref={formRef}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              {isEditable ? (
                <Grid item xs={5} md={9}>
                  <TextField
                    fullWidth
                    required
                    label="Nombre"
                    aria-label="Nombre"
                    className="Nombre"
                    defaultValue={competidor.nombre}
                    value={newData.nombre}
                    name="nombre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={validationErrors.nombre}
                    helperText={
                      validationErrors.nombre && "Este campo es requerido"
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={5} md={9}>
                  <Typography className="Titulo">{}</Typography>
                </Grid>
              )}
              {Origen === "auth" && (
                <Box xs={5} md={3}>
                  <IconButton
                    disabled={isDelete}
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={isEditable}
                    onClick={() => {
                      setIsDelete(!isDelete);
                    }}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Divider className="DividerMC" />
            {isEditable ? (
              <Box className="ContenidoN">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: { xs: 250, md: "auto" },
                      width: { xs: "100%", md: "20%" },
                      height: "auto",
                    }}
                  >
                    <Grid item xs={10} className="GridItemCN">
                      <Box className="BoxImagenCN" style={{ margin: "5px" }}>
                        <Typography className="Texto">Foto</Typography>

                        <input
                          label="Foto"
                          name="foto"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="imagenInput"
                        />
                        {
                          <img
                            src={
                              previewImgPilotoUrl
                                ? previewImgPilotoUrl
                                : `${API_BASE_URL}/images/${competidor?.foto}`
                            }
                            alt={fileName}
                            style={{
                              maxWidth: "90%",
                              maxHeight: "50%",
                              marginRight: "auto",
                              marginLeft: "auto",
                              marginTop: "16px",
                            }}
                          />
                        }
                        <Divider className="DividerCN" />

                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <label htmlFor="imagenInput">
                            <Button
                              variant="outlined"
                              component="span"
                              color="primary"
                              className="BotonBody BotonCN"
                              sx={{}}
                            >
                              Seleccionar
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                  <Box sx={{ width: { xs: "100%", md: "80%" } }}>
                    <Grid container className="GridContenedorN">
                      <Grid item style={{ marginTop: "15px" }}>
                        <TextField
                          disabled
                          required
                          type="number"
                          label="Licencia"
                          aria-label="idCompetidor"
                          className="Subtitulo"
                          style={{ whiteSpace: "pre" }}
                          defaultValue={competidor.idCompetidor}
                          value={newData.idCompetidor}
                          name="idCompetidor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={validationErrors.idCompetidor}
                          helperText={
                            validationErrors.idCompetidor &&
                            "Este campo es requerido"
                          }
                        />
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid item style={{ marginTop: "15px" }}>
                        {resClub ? (
                          <FormControl
                            fullWidth
                            sx={{ textAlign: "center" }}
                            error={hasError.club}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Club
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="idClub"
                              value={newData.idClub}
                              label="Club"
                              onChange={handleChange}
                              required
                            >
                              {dataClub?.map((club) => (
                                <MenuItem key={club.id} value={club.id}>
                                  {club.sigla}
                                </MenuItem>
                              ))}
                            </Select>
                            {hasError.club && (
                              <FormHelperText
                                sx={{ color: "#D32F2F", textAlign: "center" }}
                              >
                                Campo requerido!
                              </FormHelperText>
                            )}
                          </FormControl>
                        ) : isLoadingClub ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{
                                width: "50%",
                                height: "50%",
                                textAlign: "center",
                              }}
                            >
                              <CircularProgress
                                style={{ width: "30px", height: "30px" }}
                              />
                              <Typography
                                style={{
                                  color: "rgb(0, 150, 215)",
                                  fontSize: "15px",
                                  marginTop: "10px",
                                }}
                              >
                                Cargando Clubes
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography className="Titulo">
                            {" No hay Clubes para mostrar."}
                          </Typography>
                        )}
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid item style={{ marginTop: "15px" }}>
                        <TextField
                          required
                          type="text"
                          label="Ciudad"
                          aria-label="ciudad"
                          className="Subtitulo"
                          style={{ whiteSpace: "pre" }}
                          defaultValue={competidor.ciudad}
                          value={newData.ciudad}
                          name="ciudad"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={validationErrors.ciudad}
                          helperText={
                            validationErrors.ciudad && "Este campo es requerido"
                          }
                        />
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid item style={{ marginTop: "15px" }}>
                        <TextField
                          required
                          type="text"
                          label="Provincia"
                          aria-label="provincia"
                          className="Subtitulo"
                          style={{ whiteSpace: "pre" }}
                          defaultValue={competidor.provincia}
                          value={newData.provincia}
                          name="provincia"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={validationErrors.provincia}
                          helperText={
                            validationErrors.provincia &&
                            "Este campo es requerido"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="ContenidoN">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      maxHeight: { xs: 250, md: "auto" },
                      display: "block",
                      maxWidth: "99vw",
                      overflow: "hidden",
                      width: { xs: "100%", md: "20%" },
                      height: "auto",
                      objectFit: "cover",
                    }}
                    src={
                      competidor?.foto !== ""
                        ? `${API_BASE_URL}/images/${competidor.foto}`
                        : noimage
                    }
                    alt={"foto"}
                  />
                  <Box sx={{ width: "80%" }}>
                    <Grid container className="GridContenedorN">
                      {isDelete && (
                        <Grid item>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              pb: 5,
                            }}
                          >
                            ¿Desea Eliminar este Competidor?
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          type="number"
                          label="Licencia"
                          aria-label="idCompetidor"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          disabled
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={competidor.idCompetidor}
                        />
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        {resClub ? (
                          <FormControl
                            fullWidth
                            sx={{ textAlign: "center" }}
                            error={hasError.club}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Club
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="idClub"
                              value={newData.idClub}
                              label="Club"
                              onChange={handleChange}
                              required
                              disabled
                            >
                              {dataClub?.map((club) => (
                                <MenuItem key={club.id} value={club.id}>
                                  {club.sigla}
                                </MenuItem>
                              ))}
                            </Select>
                            {hasError.club && (
                              <FormHelperText
                                sx={{ color: "#D32F2F", textAlign: "center" }}
                              >
                                Campo requerido!
                              </FormHelperText>
                            )}
                          </FormControl>
                        ) : isLoadingClub ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{
                                width: "50%",
                                height: "50%",
                                textAlign: "center",
                              }}
                            >
                              <CircularProgress
                                style={{ width: "30px", height: "30px" }}
                              />
                              <Typography
                                style={{
                                  color: "rgb(0, 150, 215)",
                                  fontSize: "15px",
                                  marginTop: "10px",
                                }}
                              >
                                Cargando Clubes
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography className="Titulo">
                            {" No hay Clubes para mostrar."}
                          </Typography>
                        )}
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          type="text"
                          label="Ciudad"
                          aria-label="ciudad"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          disabled
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={competidor.ciudad}
                        />
                      </Grid>
                      <Box sx={{ flexGrow: 1, height: 5 }} />
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          type="text"
                          label="Provincia"
                          aria-label="provincia"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          readOnly
                          disabled
                          sx={{
                            whiteSpace: "pre",
                            borderColor: "transparent",
                            width: "100%",
                          }}
                          value={competidor.provincia}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
            <Divider className="DividerMC" />
            <Grid className="gridBotonesMC">
              {!isEditable ? (
                isDelete ? (
                  <>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Button onClick={handleDelete} className="BotonBody">
                        Eliminar
                      </Button>
                      <Button onClick={handleCancelar} className="BotonBody">
                        Cancelar
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <Grid container sx={{ justifyContent: "end" }}>
                  <Button onClick={handleEdit} className="BotonBody">
                    Guardar
                  </Button>
                  <Button onClick={handleCancelar} className="BotonBody">
                    Cancelar
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
        <ModalConfirmacion />
      </Box>
    </React.Fragment>
  );
};

export default ModalPiloto;
