import React, { useContext, useState } from "react";
import "../../Styles/ParapenteXC.css";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import Calendario from "../../../components/Calendario";
import { useGetListaCompetenciasQuery } from "../../../redux/services/competencia";
import CrearCompetencia from "../../CrearCompetencia";
import { MenuContext } from "../../../context/MenuContext";

const Competencias = () => {
  const [competencias, setCompetencias] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { menuState } = useContext(MenuContext);

  const { data: dataCompetencias, isLoading: isLoadingCompetencias } =
    useGetListaCompetenciasQuery();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataCompetencias?.listaCompetencia
          ?.map((dato) => ({
            ...dato,
            fechaInicio: dayjs(dato.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: dayjs(dato.fechaFin).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          });

        setCompetencias(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataCompetencias]);

  const totalPages = Math.ceil(competencias?.length / pageSize);
  const paginatedCompetencias = competencias?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              className="gridColumnaPXC"
              style={{ flexDirection: "column" }}
            >
              {competencias ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Subtitulo">Calendario</Typography>
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Competencias:
                  </Typography>
                  {competencias.length > 0 ? (
                    paginatedCompetencias.map((competencia) => (
                      <Calendario
                        key={competencia.id}
                        competencia={competencia}
                        Origen={"auth"}
                      />
                    ))
                  ) : (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay próximas competencias previstas.
                    </Typography>
                  )}
                </Box>
              ) : isLoadingCompetencias ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay competencias para mostrar."}
                </Typography>
              )}
              {!isNaN(totalPages) && paginatedCompetencias?.length > 0 && (
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} /{totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />

              <Box style={{ width: "100%" }} className="">
                <CrearCompetencia sinmenu={true} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Competencias;
