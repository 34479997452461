import React, { useState } from "react";
import './Styles/Calendario.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import ModalCompetencia from "./ModalCompetencia";
import dayjs from "dayjs";

function Calendario({ competencia, Origen }) {

  const [modalState, setModalState] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const handleOpenModal = () => {
    setCurrentId(competencia.id);
    setModalState(true);
  }

  const handleCloseModal = () => {
    setModalState(false);
  }

  return (
    <React.Fragment>
      <Accordion className="Acordeon">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
        >
          <Typography className="Titulo" sx={{ width: "75%", my: "15px" }}>
            {competencia.titulo}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ width: "auto", height: "auto", m: 1 }}
          />

          <Box className="boxFechaCal">
            <Typography className="Subtitulo textoPequeñoCal">
              Inicio:
            </Typography>
            <Typography className="TextoPXC textoMuyPequeñoNI">
              {dayjs(competencia.fechaInicio).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="AcordeonDetail ">
          <Typography className="Texto truncate-text">
            {
              competencia.asunto
                && (competencia.asunto.toLowerCase() === 'null' || competencia.asunto.toLowerCase() === 'undefined')
                ? '' : competencia.asunto
            }
          </Typography>

          <Button className="BotonBody" onClick={handleOpenModal}>
            Ver mas
          </Button>
          <div style={{ display: 'flex' }}>

            <ModalCompetencia
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Competencia={competencia}
              CurrentId={currentId}
              Origen={Origen}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default Calendario;
