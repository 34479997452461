import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useModificarAjusteMutation } from "../../../../../redux/services/ajustes";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const TablaAjuste = ({ mangasCAP }) => {
    const [tabla, setTabla] = useState(mangasCAP);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTabla(mangasCAP);
    }, [mangasCAP]);

    const [modificarAjuste] = useModificarAjusteMutation();

    const handleEdit = (index) => (e) => {
        const { name, value } = e.target;
        setTabla((prevTabla) => {
            const newTabla = [...prevTabla];
            newTabla[index] = {
                ...newTabla[index],
                [name]: value,
            };
            return newTabla;
        });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await Promise.all(tabla.map((item) => modificarAjuste(item)));
            toast.success("Ajustes guardados correctamente!", {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error) {
            toast.error("Error guardando los ajustes!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.error("Error updating ajustes:", error);
        } finally {
            setLoading(false);
        }
    };

    const isMobile = window.innerWidth <= 768;
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    marginTop: "15px",
                }}
            >
                <TableContainer
                    component={Paper}
                    style={{ maxWidth: isMobile ? "100%" : "40%" }}
                >
                    <Table>
                        <TableHead>
                            <TableRow sx={{ height: "50px" }}>
                                <TableCell
                                    align="center"
                                    sx={{ fontWeight: "bold", padding: "4px" }}
                                >
                                    Mangas CAP
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ fontWeight: "bold", padding: "4px" }}
                                >
                                    Mangas FAI o PWC
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tabla.map((fila, index) => (
                                <TableRow
                                    key={fila.id}
                                    sx={{ height: "30px", maxHeight: "30px", minHeight: "30px" }}
                                >
                                    <TableCell
                                        align="center"
                                        sx={{ margin: "4px", padding: "0px" }}
                                    >
                                        {fila.variable.replace("Mangas CAP ", "")}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ margin: "4px", padding: "0px" }}
                                    >
                                        <TextField
                                            id={`valor-${fila.id}`}
                                            label="Valor"
                                            variant="outlined"
                                            type="number"
                                            name="valor"
                                            value={fila.valor}
                                            onChange={handleEdit(index)}
                                            size="small"
                                            sx={{
                                                margin: "7px",
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <LoadingButton
                    loading={loading}
                    onClick={handleSave}
                    sx={{ marginTop: "15px" }}
                    variant="contained"
                    color="primary"
                >
                    Cargar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default TablaAjuste;
