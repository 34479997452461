import { createContext, useState, } from 'react';

export const ModalidadContext = createContext(null);

export default function ModalidadContextProvider({ children }) {
    const [modalidadState, setModalidadState] = useState({});


    return (
        <ModalidadContext.Provider value={{ modalidadState, setModalidadState }}>
            {children}
        </ModalidadContext.Provider>
    )
}