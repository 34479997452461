import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ListaAjuste from "./components/ListaAjuste";
import { MenuContext } from "../../../../context/MenuContext";
import { useGetListaAjusteQuery } from "../../../../redux/services/ajustes";
import TablaAjuste from "./components/TablaAjuste";

const Ajustes = () => {
  const [mangasCAP, setMangasCAP] = useState([]);
  const [otrosParametros, setOtrosParametros] = useState([]);
  const { data: dataAjuste, isLoading: isLoadingAjuste } =
    useGetListaAjusteQuery();
  const { menuState } = useContext(MenuContext);

  useEffect(() => {
    if (dataAjuste) {
      try {
        const filtrado =
          dataAjuste.listaParametros?.filter((tipo) => tipo.activo !== false) ||
          [];

        const mangasCAPFiltrados = filtrado.filter((param) =>
          param.variable.startsWith("Mangas CAP")
        );
        const otrosParametrosFiltrados = filtrado.filter(
          (param) => !param.variable.startsWith("Mangas CAP")
        );

        setMangasCAP(mangasCAPFiltrados);
        setOtrosParametros(otrosParametrosFiltrados);
      } catch (error) {
        console.error("Error al filtrar los datos:", error);
      }
    }
  }, [dataAjuste]);

  otrosParametros.sort((a, b) => {
    const order = [21, 1, 3, 4, 5, 7];
    return order.indexOf(a.id) - order.indexOf(b.id);
});

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              <Box className="boxCalendarioPXC">
                <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                  Ajustes de Cálculo de Ranking:
                </Typography>
                {isLoadingAjuste ? (
                  <CircularProgress />
                ) : (
                  <>
                    {otrosParametros?.length === 0 ? (
                      <Typography
                        className="Titulo"
                        style={{ fontSize: 14, alignSelf: "center" }}
                      >
                        No hay Ajustes para mostrar.
                      </Typography>
                    ) : (
                      otrosParametros?.map((tipo) => (
                        <ListaAjuste
                          key={tipo.id}
                          Tipo={tipo}
                          Origen={"auth"}
                        />
                      ))
                    )}
                    <div style={{ width: "100%" }}>
                      <Typography
                        className="Titulo"
                        align="center"
                        sx={{ marginTop: "25px" }}
                      >
                        Cupo Internacional:
                      </Typography>
                      {mangasCAP?.length === 0 ? (
                        <Typography
                          className="Titulo"
                          style={{ fontSize: 14, alignSelf: "center" }}
                        >
                          No hay Ajustes para mostrar.
                        </Typography>
                      ) : (
                        <TablaAjuste mangasCAP={mangasCAP} />
                      )}
                    </div>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Ajustes;
