import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authApi } from "./auth";
import { store } from "../store";
import { logout, setCredentials } from "../features/authSlice";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

const getAuthToken = () => {
  return localStorage.getItem("refreshToken");
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      const refresh = getAuthToken();

      if (refresh) {
        try {
          const refreshResult = await api.dispatch(
            authApi.endpoints.refreshToken.initiate({ refreshToken: refresh })
          );

          if (refreshResult.data.respuestaToken !== null) {
            const newToken = refreshResult.data.respuestaToken.accessToken;
            const newRefreshToken = refreshResult.data.respuestaToken.refreshToken;

            const userData = {
              respuestaToken: {
                accessToken: newToken,
                refreshToken: newRefreshToken,
              },
            };
            store.dispatch(setCredentials(userData));
            processQueue(null, newToken);

            result = await baseQuery(
              {
                ...args,
                headers: {
                  ...args.headers,
                  Authorization: `Bearer ${newToken}`,
                },
              },
              api,
              extraOptions
            );
          } else {
            processQueue("Token refresh failed", null);
            store.dispatch(logout());
            toast.error(
              "Lo sentimos, tu sesión ha expirado debido a inactividad. Por favor, vuelve a iniciar sesión para continuar.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        } catch (error) {
          processQueue(error, null);
          store.dispatch(logout());
          toast.error(
            "Error al intentar refrescar el token. Por favor, vuelve a iniciar sesión.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } finally {
          isRefreshing = false;
        }
      } else {
        store.dispatch(logout());
        toast.error(
          "No se encontró un token de refresco. Por favor, vuelve a iniciar sesión.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
      .then(token => {
        result = baseQuery(
          {
            ...args,
            headers: {
              ...args.headers,
              Authorization: `Bearer ${token}`,
            },
          },
          api,
          extraOptions
        );
        return result;
      })
      .catch(err => {
        return result;
      });
    }
  }

  return result;
};

export const rankingApi = createApi({
  reducerPath: "rankingApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Ranking"],
  endpoints: (builder) => ({
    getCalcularRanking: builder.query({
      query: () => "calcular-ranking",
      providesTags: ["Ranking"],
    }),
    getMostrarRanking: builder.query({
      query: () => "mostrar-ranking",
      providesTags: ["Ranking"],
    }),
    getMostrarRankingModalidad: builder.query({
      query: (id) => `mostrar-ranking-modalidad?idModalidad=${id}`,
      providesTags: ["Ranking"],
    }),
    getMostrarMangasRanking: builder.query({
      query: ({ idCompetidor, idModalidad }) =>
        `mostrar-mangas-ranking?idCompetidor=${idCompetidor}&idModalidad=${idModalidad}`,
      providesTags: ["Ranking"],
    }),
    clonarRanking: builder.mutation({
      query: () => {
        return {
          url: `clonar-ranking`,
          method: "POST",
        };
      },
      invalidatesTags: ["Ranking"],
    }),
    guardarHistorialRanking: builder.mutation({
      query: (id) => {
        return {
          url: `guardar-historial-ranking?idModalidad=${id}`,
          method: "POST",
        };
      },
      invalidatesTags: ["Ranking"],
    }),
    getMostrarRankingPublicadoModalidad: builder.query({
      query: (id) => `mostrar-publicacion?idModalidad=${id}`,
      providesTags: ["Ranking"],
    }),
    getMostrarHistorialRankingModalidad: builder.query({
      query: (id) => `buscar-lista-ranking?idModalidad=${id}`,
      providesTags: ["Ranking"],
    }),
    getHistorialRanikngIndividual: builder.mutation({
      query: (filtroHistorial, skip = false) => {
        if (!filtroHistorial || skip) {
          return { data: null };
        }

        let urlSegments = [];

        if (filtroHistorial.fecha !== " ") {
          urlSegments.push(`fecha=${filtroHistorial.fecha}`);
        }

        if (filtroHistorial.idModalidad !== 0) {
          urlSegments.push(`idModalidad=${filtroHistorial.idModalidad}`);
        }

        const baseUrl = "buscar-ranking-individual";
        const queryString =
          urlSegments.length > 0 ? `?${urlSegments.join("&")}` : "";

        const finalUrl = `${baseUrl}${queryString}`;

        return {
          url: finalUrl,
          method: "POST",
        };
      },
      invalidatesTags: ["Ranking"],
    }),
  }),
});

export const {
  useGetCalcularRankingQuery,
  useGetMostrarRankingQuery,
  useGetMostrarRankingModalidadQuery,
  useGetMostrarMangasRankingQuery,
  useClonarRankingMutation,
  useGuardarHistorialRankingMutation,
  useGetHistorialRanikngIndividualMutation,
  useGetMostrarRankingPublicadoModalidadQuery,
  useGetMostrarHistorialRankingModalidadQuery,
} = rankingApi;
