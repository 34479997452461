import React, { useState } from "react";
import "./Styles/NoticiasInicio.css";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import { useGetModalidadByIdQuery } from "../redux/services/modalidad";
import Novedad from "../routes/Novedad";
import NovedadInicioAdmin from "../routes/cms/pages/inicio/NovedadInicioAdmin";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function NoticiasCarrusel({ novedad, origen, type }) {
  const [modalState, setModalState] = useState(false);

  const handleCloseModal = () => {
    setModalState(false);
  };

  const { data: dataModalidad } = useGetModalidadByIdQuery(
    novedad.idModalidadFk
  );
  const [modalidad, setModalidad] = useState("");

  React.useEffect(() => {
    const fetchModalidad = async () => {
      try {
        const result = await dataModalidad;

        if (result && result.modalidadIndividual) {
          setModalidad(result.modalidadIndividual.nombre);
        }
      } catch (error) {
        console.error("Error al obtener la modalidad:", error);
      }
    };

    fetchModalidad();
  }, [dataModalidad]);

  return (
    <React.Fragment>
      <Grid
        container
        onClick={() => setModalState(!modalState)}
        style={{ heigth: 150, cursor: "pointer" }}
      >
        <Grid item xs={12} sx={{ m: 1 }} className="" key={novedad.id}>
          <Item
            sx={{
              p: 1,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
            }}
            elevation={3}
          >
            <Box
              className="Texto truncate-text"
              sx={{ maxWidth: "100%", p: 0.5 }}
            >
              <Typography
                className="TituloPXC"
                style={{
                  textAlign: "left",
                  margin: "10px",
                  fontSize: 15,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              >
                Título: <b> {novedad.titulo}</b>
              </Typography>
              <Typography
                className="TextoPXC"
                sx={{
                  textAlign: "left",
                  margin: "10px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                }}
              >
                Modalidad: <b> {modalidad} </b>
              </Typography>
            </Box>
            <Box
              className="Texto truncate-text"
              sx={{ textAlign: "center", maxWidth: "20%" }}
            >
              <Typography className="SubtituloPXC textoPequeñoNI">
                {novedad.autor}
              </Typography>
              <Typography
                className="TextoPXC textoMuyPequeñoNI"
                sx={{ width: "100%" }}
              >
                {novedad.fechaPublicacion}
              </Typography>
            </Box>
          </Item>
          {type ? (
            <NovedadInicioAdmin
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Novedad={novedad}
              Origen={origen}
            />
          ) : (
            <Novedad
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Novedad={novedad}
              Origen={origen}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NoticiasCarrusel;
