import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAgregarTablaIntermediHtmlMutation } from "../../../../../redux/services/resultados";
import { toast } from "react-toastify";

export default function TableHistorial({ extractedTable }) {
  const [tablaCompleta, setTablaCompleta] = useState([]);
  const [tabla, setTabla] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { state } = useLocation();
  const [temporada, setTemporada] = useState(extractedTable);
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setTablaCompleta(temporada);
  }, [temporada]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [agregarTablaIntermediaHtml, error, isFetching] =
    useAgregarTablaIntermediHtmlMutation();

  useEffect(() => {
    setTabla(temporada);
  }, [temporada]);

  const totalPages = Math.ceil(tabla?.length / pageSize);

  const paginatedTabla = tablaCompleta?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const tKeys =
    paginatedTabla.length > 0
      ? Object.keys(paginatedTabla[0]).filter((key) => key.startsWith("t"))
      : [];

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleEdit = (index) => (e) => {
    const { name, value } = e.target;
    const updatedTemporada = [...tablaCompleta];
    updatedTemporada[index][name] = value;
    setTablaCompleta(updatedTemporada);
  };

  const transformObject = (competidores, competencia) => {
    const nuevoFormato = competidores.map((competidor) => {
      const nuevoCompetidor = {
        posicion: competidor["#"].trim(),
        idCompetidor: competidor.id.trim(),
        nombre: competidor.name.trim(),
        genero: competidor[""].trim(),
        nacionalidad: competidor.nat.trim(),
        glider: competidor.glider.trim(),
        sponsor: competidor.sponsor.trim(),
        t: {},
        total: parseFloat(competidor.total.trim()),
        idCompetenciaFk: competencia,
      };

      Object.keys(competidor).forEach((key) => {
        if (key.startsWith("t")) {
          const valores = competidor[key].match(/[\d.]+/g);
          const numeros = valores.map((num) => parseFloat(num));
          const maxNum = Math.max(...numeros.filter((num) => !isNaN(num)));
          nuevoCompetidor.t[key] = maxNum || 0;
        }
      });
      return nuevoCompetidor;
    });
    return nuevoFormato;
  };

  const handleSave = async () => {
    try {
      handleOpen();
      const nuevoObjeto = transformObject(temporada, state.id);
      const compeId = await agregarTablaIntermediaHtml(nuevoObjeto);
      if (compeId.error) throw new Error(error);
      toast.success("Resultados publicados!", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate(-1);
    } catch (error) {
      toast.error("Error subiendo resultados de Competencia!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error updating competencia:", error);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (!isFetching) handleClose();
  }, [isFetching]);

  const isMobile = window.innerWidth <= 768;

  return (
    <React.Fragment>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Nat</TableCell>
                {tKeys.map((tKey, index) => (
                  <TableCell key={index}>{tKey}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTabla.map((fila, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: "13%", maxWidth: "13%" }}>
                    <TextField
                      id="outlined-basic"
                      style={{ width: isMobile ? "200%" : "auto" }}
                      label="Licencia"
                      variant="outlined"
                      type="number"
                      name="id"
                      value={fila.id.trim()}
                      onChange={handleEdit(
                        (currentPage - 1) * pageSize + index
                      )}
                    />
                  </TableCell>
                  <TableCell>{fila.name}</TableCell>
                  <TableCell>{fila.nat}</TableCell>
                  {tKeys.map((tKey, index) => (
                    <TableCell key={index}>{fila[tKey].trim()}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            {"<<"}
          </Button>
          <Typography variant="body1" style={{ margin: "5px 10px 0px 10px" }}>
            Pag.{currentPage} / {totalPages}
          </Typography>
          <Button
            variant="outlined"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            {">>"}
          </Button>
        </Box>
        <Divider sx={{ width: "100%", m: 2 }} />

        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          style={{ marginBottom: "20px" }}
        >
          Cargar
        </Button>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div
          id="div1"
          style={{
            p: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ textAlign: "center", fontSize: 10 }}>
            Subiendo Resultados...{" "}
          </p>
          <p style={{ textAlign: "center", color: "orange" }}>
            No actualice esta página
          </p>
        </div>
      </Backdrop>
    </React.Fragment>
  );
}
