import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";



const ErrorPage = () => {
  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <Box
        className={"contPrincipalPXC"}
      >
        <Container style={{ textAlign: "center", marginTop: "50px" }}>
          <Typography variant="h1" component="h2" gutterBottom>
            404
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Página no encontrada
          </Typography>
          <Typography variant="body1" gutterBottom>
            Lo sentimos, la página que estás buscando no existe.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleGoBack}>
            Volver al inicio
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default ErrorPage;
