import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { MenuContext } from "../../../../context/MenuContext";

import CalendarioModalidades from "./componentes/CalendarioModalidades";
import CrearModalidad from "./componentes/CrearModalidad";
import { useGetListaModalidadesQuery } from "../../../../redux/services/modalidadHome";

const Modalidades = () => {
  const [modalidades, setModalidades] = useState([]);
  const [modalidadesCount, setModalidadesCount] = useState(0);
  const {
    data: dataModalidades,
    isLoading: isLoadingModalidades,
    refetch,
  } = useGetListaModalidadesQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataModalidades?.listaModalidades?.filter(
          (modalidad) => modalidad.activo !== false
        );
        const modalidadesLimitadas = filtrado; /*?.slice(0, 8);*/
        setModalidades(modalidadesLimitadas);
        setModalidadesCount(filtrado?.length);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataModalidades]);

  const { menuState } = useContext(MenuContext);

  return (
    <React.Fragment>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container style={{ flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {modalidades ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Modalidades:
                  </Typography>
                  {modalidades?.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay modalidades para mostrar.
                    </Typography>
                  ) : (
                    modalidades.map((modalidad) => (
                      <CalendarioModalidades
                        key={modalidad.id}
                        Modalidad={modalidad}
                        Origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : isLoadingModalidades ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay modalidades para mostrar."}
                </Typography>
              )}

              <Divider sx={{ width: "100%", m: 2 }} />
              <Box style={{ width: "100%" }} className="">
                <CrearModalidad
                  sinmenu={true}
                  modalidadesCount={modalidadesCount}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Modalidades;
