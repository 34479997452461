import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import { MenuContext } from "../../../context/MenuContext";
import { useGetListaCompetenciasCmsQuery } from "../../../redux/services/competencia";
import { useGetListaNovedadesQuery } from "../../../redux/services/novedad";
import CalendarioInicio from "./inicio/CalendarioInicio";
import NoticiasInicioAdmin from "./inicio/NoticiasInicioAdmin";

const HomeCms = () => {
  const [competencias, setCompetencias] = useState();

  const { data: dataCompetencias, isLoading: isLoadingCompetencias } =
  useGetListaCompetenciasCmsQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataCompetencias?.listaCompetencia
          ?.filter((dato) => {
            const ayer = dayjs().subtract(1, "day");
            return (
              dayjs(dato.fechaInicio).isAfter(ayer, "date") ||
              dayjs(dato.fechaFin).isAfter(ayer, "date")
            );
          })
          .map((dato) => ({
            ...dato,
            fechaInicio: dayjs(dato.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: dayjs(dato.fechaFin).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          });

        setCompetencias(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataCompetencias]);

  const [novedades, setNovedades] = useState();
  const { data: dataNovedades, isLoading: isLoadingNovedades } =
    useGetListaNovedadesQuery();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataNovedades?.listaNovedades
          ?.map((dato) => ({
            ...dato,
            fechaPublicacion: dayjs(dato.fechaPublicacion).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          });

        setNovedades(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataNovedades]);

  const { menuState } = useContext(MenuContext);
  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {competencias ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Subtitulo">Calendario.</Typography>
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Próximas competencias:
                  </Typography>
                  {competencias.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay próximas competencias previstas.
                    </Typography>
                  ) : (
                    competencias?.map((competencia) => (
                      <CalendarioInicio
                        key={competencia.id}
                        competencia={competencia}
                        Origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : !isLoadingCompetencias ? (
                <Typography className="Titulo">
                  {" No hay competencias para mostrar."}
                </Typography>
              ) : (
                <>
                  {" "}
                  <CircularProgress />
                </>
              )}
              <Divider sx={{ width: "100%", m: 2 }} />
              {novedades ? (
                <Box className="boxCalendarioPXC">
                  <Typography className="Subtitulo">Calendario.</Typography>
                  <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                    Novedades:
                  </Typography>
                  {novedades.length === 0 ? (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay novedades para mostrar.
                    </Typography>
                  ) : (
                    novedades?.map((novedad) => (
                      <NoticiasInicioAdmin
                        key={novedad.id}
                        novedad={novedad}
                        origen={"auth"}
                      />
                    ))
                  )}
                </Box>
              ) : !isLoadingNovedades ? (
                <Typography className="Titulo">
                  {" No hay novedades para mostrar."}
                </Typography>
              ) : (
                <>
                  {" "}
                  <CircularProgress />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HomeCms;
