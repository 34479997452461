export const localStorageMiddleware = store => next => action => {
    const result = next(action);
    const state = store.getState();
    localStorage.setItem('modalidad', JSON.stringify(state.modalidad));
    return result;
  };
  
  export const rehydrateState = () => {
    const persistedModalidad = localStorage.getItem('modalidad');
    return persistedModalidad ? { modalidad: JSON.parse(persistedModalidad) } : undefined;
  };