import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import MenuLateral from "../components/MenuLateral";
import PieDePagina from "../components/PieDePagina";
import { MenuContext } from "../context/MenuContext";

const Construccion = () => {
  const { setMenuState } = React.useContext(MenuContext);

  React.useEffect(() => {
    setMenuState(false);

    return () => {
      setMenuState(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { menuState } = useContext(MenuContext);
  const { state } = useLocation();
  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const opcMenu = [
    {
      opcion: "Atrás",
      link: "",
      action: handleGoBack,
      state,
    },
  ];

  return (
    <>
      <MenuLateral opcMenu={opcMenu} menuTitulo={"En construccion"} typeMenu={false} />
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
      >
        <Typography sx={{ textAlign: "center", p: 20 }}>
          Página en construcción
        </Typography>
      </Box>
      <PieDePagina />
    </>
  );
};

export default Construccion;
