import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { MenuContext } from "../../../../context/MenuContext";
import ListaSeleccion from "./componentes/ListaSeleccion";
import CrearSeleccion from "./componentes/CrearSeleccion";

const Seleccion = () => {
  const { menuState } = useContext(MenuContext);
  return (
    <>
      <Box
        className={`${
          menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
        }`}
      >
        <Grid container style={{ flexDirection: "row" }}>
          <Grid item xs={12} className="gridColumnaPXC">
            <Box className="boxCalendarioPXC">
              <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                Selección Nacional:
              </Typography>
              <ListaSeleccion />
            </Box>
            <Divider sx={{ width: "100%", m: 2 }} />
            <Box style={{ width: "100%" }} className="">
              <CrearSeleccion />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Seleccion;
