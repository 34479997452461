import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Styles/CrearNovedad.css";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import BarraNavegacion from "../components/BarraNavegacion.js";
import PieDePagina from "../components/PieDePagina";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Competencia from "./Competencia.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useAgregarCompetenciaMutation,
  useAgregarImagenCompetenciaMutation,
} from "../redux/services/competencia.js";
import { useGetListaModalidadesSelectQuery } from "../redux/services/modalidad.js";
import { useGetListaTipoCompetenciaQuery } from "../redux/services/tipoCompetencia.js";
import Dropzone from "../components/Dropzone.js";

dayjs.locale({
  ...require("dayjs/locale/es"),
  weekStart: 0, // 0 representa el domingo
});

function CrearCompetencia({ sinmenu }) {
  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState([]);
  const desarrolloCompetenciaNewRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [postId, setPostId] = useState();
  const [hasError, setHasError] = useState({
    modalidad: false,
    tipoCompetencia: false,
  });

  const initialState = {
    fechaInicio: dayjs().format("YYYY-MM-DD"),
    fechaFin: dayjs(Date.now()).format("YYYY-MM-DD"),
    titulo: "",
    asunto: "",
    desarrollo: "",
    idModalidadFk: 0,
    idTipoCompetenciaFk: 0,
    ubicacion: "",
    idCategoriaFk: 2,
    reglasPdf: "",
    imagen: "",
    bannerPublicitario: "",
  };
  const [competencia, setCompetencia] = useState(initialState);

  const [validationErrors, setValidationErrors] = useState({
    titulo: false,
    ubicacion: false,
    desarrollo: false,
    asunto: false,
  });

  const handleBlur = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompetencia({
      ...competencia,
      [name]: value,
    });

    if (name === "idModalidadFk") {
      setHasError({ ...hasError, modadlidad: false });
      return;
    }
    if (name === "idTipoCompetenciaFk") {
      setHasError({ ...hasError, tipoCompetencia: false });
      return;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const [
    imagenVistaPreviaCompetenciaCrear,
    setImagenVistaPreviaCompetenciaCrear,
  ] = useState("");
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setCompetencia({
        ...competencia,
        imagen: file,
      });
      const readerVistaPreviaImg = new FileReader();
      readerVistaPreviaImg.onload = (event) => {
        setImagenVistaPreviaCompetenciaCrear(event.target.result);
      };
      readerVistaPreviaImg.readAsDataURL(file);
    }
  };

  const [modalStateCompetencia, setModalStateCompetencia] = useState(false);

  const handleOpenModal = () => {
    setModalStateCompetencia(true);
  };

  const handleCloseModal = () => {
    setModalStateCompetencia(false);
  };
  const [errorInicio, setErrorInicio] = React.useState(null);
  const [errorFin, setErrorFin] = React.useState(null);

  const errorMessageInicio = React.useMemo(() => {
    switch (errorInicio) {
      case "disablePast": {
        return "Por favor seleccione una fecha vigente";
      }
      case "minDate":
      case "maxDate": {
        return "Fecha INICIO no debe ser superior a fecha de fin";
      }
      case "invalidDate": {
        return "Fecha inválida";
      }
      default: {
        return "";
      }
    }
  }, [errorInicio]);

  const errorMessageFin = React.useMemo(() => {
    switch (errorFin) {
      case "disablePast": {
        return "Por favor seleccione una fecha vigente";
      }
      case "maxDate":
      case "minDate": {
        return "Fecha inicio no debe ser superior a fecha de FIN";
      }
      case "invalidDate": {
        return "Fecha inválida";
      }
      default: {
        return "";
      }
    }
  }, [errorFin]);

  const handleRequired = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      titulo: competencia.titulo.trim() === "",
      ubicacion: competencia.ubicacion.trim() === "",
      desarrollo: competencia.desarrollo.trim() === "",
    }));
  };

  const [agregarCompetencia, error] = useAgregarCompetenciaMutation();
  const [agregarImagenCompetencia] = useAgregarImagenCompetenciaMutation();
  const handlePublicar = async (e) => {
    e.preventDefault();

    handleRequired();

    if (validationErrors.titulo) {
      toast.error("Campo título  obligatorio!");
      return;
    }
    if (validationErrors.ubicacion) {
      toast.error("Campo ubicación  obligatorio!");
      return;
    }
    if (validationErrors.desarrollo) {
      toast.error("Campo desarrollo  obligatorio!");
      return;
    }
    if (competencia.idModalidadFk === 0) {
      setHasError({ ...hasError, modalidad: true });
      toast.error("Falta modalidad!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, modalidad: false });
    }
    if (competencia.idTipoCompetenciaFk === 0) {
      setHasError({ ...hasError, tipoCompetencia: true });
      toast.error("Falta tipo de competencia!", {
        autoClose: 2000,
      });
      return;
    } else {
      setHasError({ ...hasError, tipoCompetencia: false });
    }

    try {
      const resp = await agregarCompetencia(competencia);
      if (resp.error) throw new Error(error);
      setPostId(resp.data.mensaje);
      const editorInstance = window.tinymce?.get("desarrolloCompetenciaNew");
      if (editorInstance) {
        editorInstance.setContent("");
      }
      setCompetencia(initialState);
      setFileName("");
      toast.success("Competencia publicada!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Error creando la competencia!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error creating competencia:", error);
    }
  };

  useEffect(() => {
    const AgregarImagen = async () => {
      base64?.map(async (img, i) => {
        try {
          if (!postId) return;
          const resp = await agregarImagenCompetencia({
            imagen: img,
            idCompetencia: postId,
          });
          if (resp.error) throw new Error(error);

          toast.success("Imagen Agregada!", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "success1",
          });
        } catch (error) {
          toast.error("Error agregando imágenes!", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "success1",
          });
        }
      });
    };

    AgregarImagen();

    return () => {
      setBase64([]);
    };
  }, [postId]);

  const [dataModalidades, setDataModalidades] = useState();
  const { data: resModalidades, isLoading: isLoadingModalidad } = useGetListaModalidadesSelectQuery();

  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }
    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  const [dataTipoCompetencias, setDataTipoCompetencias] = useState();
  const { data: resTipoCompetencia, isLoading: isLoadingTipoCompetencia } = useGetListaTipoCompetenciaQuery();

  React.useEffect(() => {
    try {
      setDataTipoCompetencias(resTipoCompetencia?.listaTipoCompetencia);
    } catch (error) {
      console.log("Error al obtener los Tipo de Competencia:", error);
    }

    return () => {
      setDataTipoCompetencias();
    };
  }, [resTipoCompetencia]);

  const handleChangeDesarrollo = useCallback(
    (e) => {
      e.preventDefault();
      const { value } = e.target;
      setCompetencia({
        ...competencia,
        desarrollo: value,
      });

      if (isNaN(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          desarrollo: value.trim() === "",
        }));
      }
    },
    [competencia]
  );

  useEffect(() => {
    setCompetencia({
      ...competencia,
      desarrollo: editorContent,
    });
  }, [editorContent]);

  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    const editorInstance = window.tinymce?.get("desarrolloNovedadNew");
    if (editorInstance) {
      editorInstance.setContent("");
    }
    setIsEditable(true);
    return () => {
      setIsEditable(false);
    };
  }, []);

  React.useEffect(() => {
    const initEditor = () => {
      if (desarrolloCompetenciaNewRef.current) {
        window.tinymce?.init({
          selector: "#desarrolloCompetenciaNew",
          menubar: false,
          min_height: 250,
          max_height: 250,
          width: "100%",
          plugins: ["link"],
          toolbar:
            "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | link",
          branding: false,
          setup: (editor) => {
            editor.on("init", () => {
              const editorInstance = window.tinymce?.get(
                "desarrolloCompetenciaNew"
              );
              if (editorInstance) {
                editorInstance.setContent("");
              }
            });

            editor.on("change", (e) => {
              const content = editor.getContent();
              setEditorContent(content);
            });

            editor.on("paste", (e) => {
              e.preventDefault();

              const clipboardData = e.clipboardData || window.clipboardData;
              const html = clipboardData.getData("text/html");
              const cleanedHtml = cleanHtmlContent(html);
              editor.insertContent(cleanedHtml);
            });

            editor.on("dragover dragenter", (e) => {
              const types = e.dataTransfer.types;
              if (Array.from(types).some((type) => type === "Files")) {
                e.preventDefault();
              }
            });

            editor.on("drop", (e) => {
              const types = e.dataTransfer.types;
              if (Array.from(types).some((type) => type === "Files")) {
                e.preventDefault();
              }
            });
          },
        });
      }
    };

    const cleanHtmlContent = (html) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      const cleanElement = (element) => {
        if (element.nodeType === Node.ELEMENT_NODE) {
          element.removeAttribute("style");
          element.removeAttribute("class");
          if (element.style && element.style.backgroundImage) {
            element.style.backgroundImage = "none";
          }
        }

        element.childNodes.forEach((child) => {
          cleanElement(child);
        });
      };
      cleanElement(tempElement);

      const imgElements = tempElement.querySelectorAll("img");
      imgElements.forEach((img) => {
        img.parentNode.removeChild(img);
      });

      const cleanedHtml = tempElement.innerHTML;
      tempElement.remove();

      return cleanedHtml;
    };

    initEditor();

    return () => {
      if (window.tinymce?.get(desarrolloCompetenciaNewRef.current?.id)) {
        window.tinymce.get(desarrolloCompetenciaNewRef.current?.id).remove();
      }
    };
  }, [isEditable]);

  return (
    <React.Fragment>
      <Box>
        {!sinmenu && <BarraNavegacion />}
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear publicación de Competencia
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container className="GridItemCN">
              <Grid item md={4} xs={12}>
                <TextField
                  label="Título"
                  name="titulo"
                  value={competencia.titulo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.titulo}
                  helperText={
                    validationErrors.titulo && "Este campo es requerido"
                  }
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(competencia.fechaInicio)}
                    label="Fecha inicio"
                    name="fechaInicio"
                    disableTextEditor
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    onChange={(newValue) => {
                      const fecha = dayjs(newValue).format("YYYY-MM-DD");
                      try {
                        setCompetencia((prevCompetencia) => ({
                          ...prevCompetencia,
                          fechaInicio: fecha,
                        }));
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    onError={(newError) => setErrorInicio(newError)}
                    slotProps={{
                      textField: {
                        style: { width: "100%" },
                        helperText: errorMessageInicio,
                      },
                    }}
                    sx={{ marginBottom: 2 }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(competencia.fechaFin)}
                    label="Fin"
                    name="fechaFin"
                    minDate={dayjs(competencia.fechaInicio)}
                    disableTextEditor
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    onChange={(newValue) => {
                      const fecha = dayjs(newValue).format("YYYY-MM-DD");
                      try {
                        setCompetencia((prevCompetencia) => ({
                          ...prevCompetencia,
                          fechaFin: fecha,
                        }));
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    onError={(newError) => setErrorFin(newError)}
                    slotProps={{
                      textField: {
                        style: { width: "100%" },
                        helperText: errorMessageFin,
                      },
                    }}
                    sx={{ marginBottom: 2 }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={4}>
                <Box sx={{ width: "100%", marginBottom: 2 }}>
                  {resModalidades ? (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Modalidad
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="idModalidadFk"
                        value={competencia.idModalidadFk}
                        label="Modalidad"
                        onChange={handleChange}
                        required
                      >
                        {dataModalidades?.map((modalidad) => (
                          <MenuItem key={modalidad.id} value={modalidad.id}>
                            {modalidad.nombre}
                          </MenuItem>
                        ))}
                        <MenuItem style={{ display: "none" }} key={0} value={0}>
                          {" "}
                        </MenuItem>
                      </Select>
                      {hasError.modalidad && (
                        <FormHelperText sx={{ color: "#D32F2F" }}>
                          Campo requerido!
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : isLoadingModalidad ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          width: "50%",
                          height: "50%",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ width: "30px", height: "30px" }}
                        />
                        <Typography
                          style={{
                            color: "rgb(0, 150, 215)",
                            fontSize: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Cargando Modalidad
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className="Titulo">
                      {" No hay Modalidad para mostrar."}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Grid item xs={12} md={4}>
                <Box sx={{ width: "100%", marginBottom: 2 }}>
                  {resTipoCompetencia ? (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo de Competencia
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="idTipoCompetenciaFk"
                        value={competencia.idTipoCompetenciaFk}
                        label="Tipo de Competencia"
                        onChange={handleChange}
                        required
                      >
                        {dataTipoCompetencias?.map((tipo) => (
                          <MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.nombre}
                          </MenuItem>
                        ))}
                        <MenuItem style={{ display: "none" }} key={0} value={0}>
                          {" "}
                        </MenuItem>
                      </Select>
                      {hasError.tipoCompetencia && (
                        <FormHelperText sx={{ color: "#D32F2F" }}>
                          Campo requerido!
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : isLoadingTipoCompetencia ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          width: "50%",
                          height: "50%",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ width: "30px", height: "30px" }}
                        />
                        <Typography
                          style={{
                            color: "rgb(0, 150, 215)",
                            fontSize: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Cargando Tipo de Competencia
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className="Titulo">
                      {" No hay Modalidad para mostrar."}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Box sx={{ minWidth: 120, marginBottom: 2 }}>
                  
                </Box>
              </Grid> */}
              <Box sx={{ flexGrow: 1 }} />
              <Grid item xs={12} md={3}>
                <TextField
                  label="Ubicación"
                  name="ubicacion"
                  value={competencia.ubicacion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.ubicacion}
                  helperText={
                    validationErrors.ubicacion && "Este campo es requerido"
                  }
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridItemCN">
              <Grid item xs={12}>
                <TextField
                  label="Link"
                  name="asunto"
                  value={competencia.asunto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  multiline
                  rows={1}
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid
              container
              className="GridItemCN"
              style={{ minHeight: 262, marginTop: -7 }}
            >
              <Grid
                item
                xs={12}
                md={8}
                className="GridItemCN"
                style={{ minHeight: 262, marginTop: -7 }}
              >
                <TextField
                  inputRef={desarrolloCompetenciaNewRef}
                  InputProps={{ disableUnderline: true }}
                  style={{ height: 262, maxHeight: 262 }}
                  label="Desarrollo"
                  name="desarrollo"
                  value={editorContent}
                  onChange={handleChangeDesarrollo}
                  onBlur={handleBlur}
                  fullWidth
                  required
                  multiline
                  rows={9}
                  error={validationErrors.desarrollo}
                  helperText={
                    validationErrors.desarrollo && "Este campo es requerido"
                  }
                  id="desarrolloCompetenciaNew"
                  variant="standard"
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                className="GridItemCN"
                style={{ minHeight: 240 }}
              >
                <Box className="BoxImagenCN" style={{ minHeight: 240 }}>
                  <Typography className="Texto">Imagen Encabezado</Typography>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none", minHeight: 250 }}
                    id="imagenInput"
                  />
                  {competencia.imagen && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        objectFit: "fill",
                        maxHeight: "50%",
                      }}
                    >
                      <img
                        src={
                          imagenVistaPreviaCompetenciaCrear
                            ? imagenVistaPreviaCompetenciaCrear
                            : null
                        }
                        alt={fileName ? fileName : "Vista previa de la imagen"}
                        style={{ maxWidth: "30%", marginTop: "16px" }}
                      />
                    </Box>
                  )}
                  <Divider className="DividerCN" />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <label htmlFor="imagenInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className="BotonBody BotonCN"
                      >
                        Seleccionar
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ flexDirection: "column" }}>
              <Dropzone className="" setBase64={setBase64} base64={base64} />
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handleOpenModal}>
                Previsualizar
              </Button>
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
      <Competencia
        key={competencia}
        modalStateCompetencia={modalStateCompetencia}
        CloseModal={handleCloseModal}
        Competencia={competencia}
        imagenVistaPreviaCompetenciaCrear={imagenVistaPreviaCompetenciaCrear}
        base64={base64}
      />
    </React.Fragment>
  );
}

export default CrearCompetencia;
