import React, { useState } from "react";

import "../../../../../components/Styles/Calendario.css";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useGetHtmlByIdQuery } from "../../../../../redux/services/resultados";
import ResultadosMangasDetalle from "./ResultadosMangasDetalle";

function ListarResultadoCompetidor() {
  const [htmlData, setHtmlData] = React.useState();
  const [mangas, setMangas] = useState([]);
  const { state } = useLocation();

  const { data, isLoading } = useGetHtmlByIdQuery(state ? state.id : null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  React.useEffect(() => {
    const fetchDataHtml = async () => {
      try {
        const result = await data;

        if (result && result.datosHTML) {
          setHtmlData(result.datosHTML);
        }
      } catch (error) {
        console.error("Error al obtener datosHTML:", error);
      }
    };

    fetchDataHtml();
  }, [data]);

  const totalPages = Math.ceil(htmlData?.length / pageSize);

  const paginatedList = htmlData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const [modalState, setModalState] = useState(false);

  const handleCloseModal = () => {
    setModalState(false);
  };
  const handleOpenModal = (t) => {
    setMangas(t);
    setModalState(true);
  };

  return (
    <React.Fragment>
      <Grid container className="GridItemCN" sx={{ margin: "10px" }}>
        <Box className="Acordeon">
          <Box style={{ marginTop: "1%" }}>
            {!isLoading ? (
              htmlData ? (
                <Box>
                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        style={{
                          width: "100%",
                          alignContent: "center",
                          alignSelf: "center",
                          height: "auto",
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Posición
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Id
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Nombre
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Nacionalidad
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Género
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" className="Titulo">
                              Total
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              className="Titulo"
                            ></Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {paginatedList.map((item) => (
                        <TableBody
                          sx={{}}
                          style={{
                            width: "98%",
                            alignContent: "center",
                            alignSelf: "center",
                            height: "auto",
                          }}
                        >
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.posicion}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.idCompetidor}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {" "}
                              {item.nombre}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.nacionalidad ? item.nacionalidad : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.genero ? item.nacionalidad : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="body">
                              {item.total}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ width: "5%" }}>
                            <Typography
                              onClick={() => {
                                handleOpenModal(item.t);
                              }}
                              variant="body1"
                              className="Titulo"
                            >
                              <InfoIcon color="primary" />
                            </Typography>
                          </TableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                  <Divider sx={{ width: "100%", m: 2 }} />
                  <Box mt={2} display="flex" justifyContent="center">
                    <Button
                      variant="outlined"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      {"<<"}
                    </Button>
                    <Typography
                      variant="body1"
                      style={{ margin: "5px 10px 0px 10px" }}
                    >
                      Pag.{currentPage} / {totalPages}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      {">>"}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Typography
                  className="Titulo"
                  sx={{ textAlign: "center", my: 2 }}
                >
                  No hay Resultados para Mostrar
                </Typography>
              )
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
      </Grid>
      <ResultadosMangasDetalle
        modalState={modalState}
        closeModal={handleCloseModal}
        mangas={mangas}
        idCompetencia={state.id}
      />
    </React.Fragment>
  );
}

export default ListarResultadoCompetidor;
