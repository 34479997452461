import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useCargaManualChicaHtmlMutation } from "../../../../../redux/services/resultados";

const SubirMangasManual = ({ setActualizar }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    task: false,
    date: false,
    distance: false,
    ftvValidity: false,
    type: false,
  });

  const initialState = {
    task: "",
    date: "",
    distance: 0,
    ftvValidity: "",
    type: "",
    idCompetencia: "",
    activo: true,
  };

  const { state } = useLocation();

  const [manga, setManga] = React.useState(initialState);

  const handleBlur = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setManga({
      ...manga,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleRequired = () => {
    const errors = {
      nombre: manga.task.trim() === "",
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }

    return false;
  };

  const [cargaManualChicaHtml, error] = useCargaManualChicaHtmlMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      const tabla = {
        ...manga,
        idCompetencia: state.id,
      };
      const modId = await cargaManualChicaHtml(tabla);
      if (modId.error) throw new Error(error);
      toast.success("Manga agregada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Error cargando Manga!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error cargando Manga:", error);
    }
  };

  return (
    <React.Fragment>
      <Box style={{ width: "100%", marginTop: "10px" }}>
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Mangas
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Task"
                  name="task"
                  value={manga.task}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.task}
                  helperText={
                    validationErrors.task && "Este campo es requerido"
                  }
                />
                <TextField
                  sx={{ mx: 1 }}
                  name="date"
                  type="datetime-local"
                  value={manga.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.date}
                  helperText={
                    validationErrors.date && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Distance"
                  name="distance"
                  value={manga.distance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.distance}
                  helperText={
                    validationErrors.distance && "Este campo es requerido"
                  }
                />
                <TextField
                  sx={{ mx: 1 }}
                  label="FTV Validity"
                  name="ftvValidity"
                  value={manga.ftvValidity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.ftvValidity}
                  helperText={
                    validationErrors.ftvValidity && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Type"
                  name="type"
                  value={manga.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.type}
                  helperText={
                    validationErrors.type && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Agregar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SubirMangasManual;
