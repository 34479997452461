import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetTablaChicaHtmlByIdQuery } from "../../../../../redux/services/resultados";
import ListarManga from "./ListarMangas";

const ResultadosMangasDetalle = ({
  modalState,
  closeModal,
  mangas,
  idCompetencia,
}) => {
  const [datosTablita, setDatosTablita] = useState();
  const { data, error } = useGetTablaChicaHtmlByIdQuery(idCompetencia);

  useEffect(() => {
    try {
      if (error) throw new Error(JSON.stringify(error));
      if (data?.datosTablaChica) setDatosTablita(data?.datosTablaChica);
    } catch (error) {
      console.log("error: ", error);
    }

    return () => {
      setDatosTablita([]);
    };
  }, [data]);

  return (
    <React.Fragment>
      <Modal open={modalState} onClose={closeModal} className="ModalC">
        <Paper sx={{ p: "10%" }}>
          <Typography className="Titulo" sx={{ textAlign: "center", mb: "5%" }}>
            Detalle de Mangas
          </Typography>
          {datosTablita?.length > 0 && <ListarManga Task={datosTablita} />}
          <Table>
            <TableHead
              sx={{}}
              style={{
                width: "100%",
                alignContent: "center",
                alignSelf: "center",
                height: "auto",
              }}
            >
              <TableRow>
                {Array.from(new Array(mangas.length)).map((e, i) => (
                  <TableCell key={i + 1}>
                    <Typography variant="body1" className="Titulo">
                      {"T" + (i + 1)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{}}
              style={{
                width: "98%",
                alignContent: "center",
                alignSelf: "center",
                height: "auto",
              }}
            >
              <TableRow>
                {mangas &&
                  mangas.map((manga) => <TableCell>{manga}</TableCell>)}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};

export default ResultadosMangasDetalle;
