import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MenuLateral from "../components/MenuLateral";
import NoticiasCarrusel from "../components/NoticiasCarrusel";
import { useGetListaNovedadesQuery } from "../redux/services/novedad";
import { MenuContext } from "../context/MenuContext";
import { ModalidadContext } from "../context/ModalidadContext";
import { useSelector } from "react-redux";

const ModalidadCnd = () => {
  const state = useSelector((state) => state.modalidad);

  const [paginaActual, setPaginaActual] = React.useState(0);
  const noticiasPorPagina = 1; // Puedes ajustar este valor según tus necesidades

  const url = "https://cnd.favl.info";

  const { menuState } = useContext(MenuContext);
  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },

    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },

    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const [novedades, setNovedades] = React.useState();

  const {
    data: dataNovedades,
    isError,
    isLoading,
  } = useGetListaNovedadesQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataNovedades?.listaNovedades
          .map((dato) => ({
            ...dato,
            fechaPublicacion: dayjs(dato.fechaPublicacion).format("YYYY-MM-DD"),
          }))
          .filter((dato) => dato.idModalidadFk === state.modalidad.id)
          .filter((dato) => dato.activo !== false);

        const datosFiltradosOrdenados = datosFiltrados.sort((a, b) =>
          dayjs(b.fechaPublicacion).isAfter(dayjs(a.fechaPublicacion)) ? 1 : -1
        );

        setNovedades(datosFiltradosOrdenados);
      } catch (error) {
        console.error("Error al obtener novedades:", error);
      }
    };

    if (!isLoading && !isError) {
      fetchData();
    }

    return () => {
      setNovedades();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNovedades, isLoading, isError]);

  const novedadesPaginated = novedades?.slice(
    paginaActual * noticiasPorPagina,
    (paginaActual + 1) * noticiasPorPagina
  );

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral opcMenu={opcMenu} menuTitulo={"CND"} typeMenu={false} />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                Novedades:
              </Typography>
              {!isLoading ? (
                novedades && novedadesPaginated?.length === 0 ? (
                  <Box sx={{}}>
                    <Typography
                      className="Titulo"
                      sx={{ mt: 2 }}
                      style={{
                        textAlign: "center",
                        aspectRatio: 10,
                        fontSize: 14,
                        alignSelf: "center",
                      }}
                    >
                      No hay novedades para mostrar.
                    </Typography>
                  </Box>
                ) : (
                  novedadesPaginated?.map((novedad) => (
                    <NoticiasCarrusel
                      key={novedad.id}
                      novedad={novedad}
                      origen={"inicio"}
                    />
                  ))
                )
              ) : (
                <CircularProgress color="primary" />
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => setPaginaActual((prev) => Math.max(prev - 1, 0))}
                disabled={paginaActual === 0}
              >
                Anterior
              </Button>
              <Button
                onClick={() =>
                  setPaginaActual((prev) =>
                    Math.min(
                      prev + 1,
                      Math.ceil(novedades?.length / noticiasPorPagina) - 1
                    )
                  )
                }
                disabled={
                  paginaActual ===
                  Math.ceil(novedades?.length / noticiasPorPagina) - 1
                }
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ justifyContent: "end" }}>
            <Typography
              component="a"
              href={url}
              target="_blank"
              style={{
                fontSize: "100%",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              sx={{ mb: 1, mx: 1 }}
            >
              Ir a: {url}
            </Typography>
          </Grid>

          <Grid
            container
            sx={{
              height: "65vh",
              border: "1px dashed rgba(10,30,50,0.2)",
              p: 1,
            }}
          >
            <Grid item xs={12}>
              <iframe
                title="Tu Título"
                src={url}
                width="100%"
                height="100%"
                allowFullScreen
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ModalidadCnd;
