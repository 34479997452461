import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MenuContext } from '../../../../context/MenuContext';
import { useGetListaCompetenciasQuery } from '../../../../redux/services/competencia';
import { useGetCalcularRankingQuery } from '../../../../redux/services/ranking';
import '../../../../routes/Styles/CrearNovedad.css';
import LayoutResultadosPublicados from './LayoutResultadosPublicos';
import MostrarResultados from './componentes/MostrarResultados';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ResultadosPublicados = () => {

    const { menuState } = useContext(MenuContext);
    //const { state } = useLocation();
    const state = useSelector((state) => state.modalidad);
    const [competencias, setCompetencias] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // número de pilotos por página

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const { data: dataCompetencias, isLoading: isLoadingCompetencias } = useGetListaCompetenciasQuery();
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const datosFiltrados = dataCompetencias?.listaCompetencia
                    ?.filter((dato) => {
                        const hoy = dayjs();
                        return (dayjs(dato.fechaInicio).isBefore(hoy, 'date') || dayjs(dato.fechaFin).isBefore(hoy, 'date'));
                    })
                    ?.filter((dato) => {
                        return (dato.idModalidadFk === state?.modalidad?.id);
                    })
                    ?.filter((dato) => {
                        return (dato.tieneHtml === true);
                    })
                    .map((dato) => ({
                        ...dato,
                        fechaInicio: dayjs(dato.fechaInicio).format('YYYY-MM-DD'),
                        fechaFin: dayjs(dato.fechaFin).format('YYYY-MM-DD'),
                    }))
                    .filter((dato) => {
                        return (dato.activo !== false);
                    });

                setCompetencias(datosFiltrados);
            } catch (error) {
                console.error('Error al obtener los posts:', error);
            }
        };

        // Para Llamar a la función fetchData al montar el componente
        fetchData();
    }, [dataCompetencias]);

    //#region paginacion
    const totalPages = Math.ceil(competencias?.length / pageSize);

    const paginatedCompetencias = competencias?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    //#endregion paginacion

    const { getCalcularRanking, error, isLoading } = useGetCalcularRankingQuery();
    const handleCalcularRanking = async (e) => {
        e.preventDefault();
        handleOpen();
        try {
            const resp = await getCalcularRanking;
            if (resp.error) throw new Error(error)

            toast.success('Ranking calculado!', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!isLoading) handleClose();
    }, [isLoading])


    return (
        <LayoutResultadosPublicados
            nombre='Resultados'

        >
            <Box>
                <Box
                    className={`${menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
                        }`}
                >
                    <Grid container sx={{ height: '100%' }} spacing={2}>
                        <Grid item xs={12} style={{ flexDirection: 'column' }} className="gridColumnaPXC">
                            {
                                paginatedCompetencias
                                    ?
                                    <Box className="boxCalendarioPXC">
                                        <div style={{ marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <Typography className="Subtitulo">Calendario</Typography>
                                                <Typography className="Titulo" sx={{ marginBottom: "15px" }}>
                                                    Competencias:
                                                </Typography>
                                            </div>

                                        </div>

                                        {
                                            paginatedCompetencias.length > 0
                                                ?
                                                paginatedCompetencias.map((competencia) => (
                                                    <>
                                                        <MostrarResultados key={competencia.id} competencia={competencia} Origen={'auth'} />
                                                    </>

                                                ))
                                                :
                                                <Typography className="Titulo" style={{ fontSize: 14, alignSelf: 'center' }}>
                                                    No hay competencias para mostrar.
                                                </Typography>
                                        }

                                    </Box>
                                    :
                                    isLoadingCompetencias
                                        ?
                                        <CircularProgress />
                                        :
                                        <Typography className="Titulo">{' No hay competencias para mostrar.'}</Typography>
                            }

                            {(!isNaN(totalPages) && paginatedCompetencias?.length > 0) &&
                                <Box mt={2} display="flex" justifyContent="center" alignItems={"center"}>
                                    <Button
                                        variant="outlined" onClick={handlePrevPage} disabled={currentPage === 1}>
                                        {'<<'}
                                    </Button>
                                    <Typography variant="body1" style={{ margin: '5px 10px 0px 10px' }}>
                                        Pag.{currentPage} /{totalPages}
                                    </Typography>
                                    <Button variant="outlined" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                        {'>>'}
                                    </Button>
                                </Box>
                            }
                        </Grid>

                    </Grid >
                </Box >
            </Box >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <div id='div1' style={{ p: 5, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <CircularProgress color="inherit" />
                    <p style={{ textAlign: 'center', fontSize: 10 }}>Calculando... </p>
                    <p style={{ textAlign: 'center', color: "orange" }}>No actualice esta página</p>
                </div>
            </Backdrop>
        </LayoutResultadosPublicados>
    )
}

export default ResultadosPublicados