import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../components/BarraNavegacion";
import { useAgregarMensajeContactoMutation } from "../redux/services/contacto";

const Contacto = () => {
  const initialState = {
    nombre: "",
    apellido: "",
    email: "",
    mensaje: "",
  };

  const [mensajeContacto, setMensajeContacto] = useState(initialState);

  const [validationErrors, setValidationErrors] = useState({
    nombre: false,
    apellido: false,
    email: false,
    mensaje: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMensajeContacto({
      ...mensajeContacto,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    setValidationErrors({ email: isValid ? false : true });
    return isValid ? "" : "Por favor, ingrese un email válido";
  };

  const handleRequired = () => {
    const errors = {
      nombre: !mensajeContacto?.nombre || mensajeContacto.nombre.trim() === "",
      apellido:
        !mensajeContacto?.apellido || mensajeContacto.apellido.trim() === "",
      mensaje:
        !mensajeContacto?.mensaje || mensajeContacto.mensaje.trim() === "",
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return true;
    }
    return false;
  };

  const [agregarMensajeContacto, error] = useAgregarMensajeContactoMutation();

  const handleEnviar = async (e) => {
    e.preventDefault();
    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }
    if (validateEmail(mensajeContacto.email) !== "") {
      toast.error(validateEmail(mensajeContacto.email));
      return;
    }
    try {
      const compeId = await agregarMensajeContacto(mensajeContacto);
      if (compeId.error) throw new Error(error);
      setMensajeContacto(initialState);
      toast.success("Mensaje enviado con éxito!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error enviando Mensaje!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <BarraNavegacion />
      <Grid
        sx={{
          width: "100vw",
          height: "100vh",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100vw" : "50vw",
            mt: !isMobile ? "8rem" : "5rem",
            pb: "1rem",
          }}
        >
          <Box className="ContenidoPpalCN" sx={{}}>
            <Grid container className="GridPpalCN">
              <Grid item className="GridItemCN">
                <Typography className="Titulo TituloPpalCN">
                  Contacto
                </Typography>
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container className="GridPpalCN">
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  label="Nombre"
                  name="nombre"
                  required
                  fullWidth
                  value={mensajeContacto.nombre}
                  onChange={handleChange}
                  error={validationErrors.nombre}
                  helperText={
                    validationErrors.nombre && "Este campo es requerido"
                  }
                />
              </Grid>
              <Divider className="DividerCN" />
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  label="Apellido"
                  name="apellido"
                  required
                  fullWidth
                  value={mensajeContacto.apellido}
                  onChange={handleChange}
                  error={validationErrors.apellido}
                  helperText={
                    validationErrors.apellido && "Este campo es requerido"
                  }
                />
              </Grid>
              <Divider className="DividerCN" />
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  required
                  value={mensajeContacto.email}
                  onChange={handleChange}
                  error={validationErrors.email}
                  helperText={validationErrors.email && "Email inválido"}
                />
              </Grid>
              <Divider className="DividerCN" />
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  label="Mensaje"
                  name="mensaje"
                  fullWidth
                  required
                  rows={5}
                  multiline
                  value={mensajeContacto.mensaje}
                  onChange={handleChange}
                  error={validationErrors.mensaje}
                  helperText={
                    validationErrors.mensaje && "Este campo es requerido"
                  }
                />
              </Grid>
              <Grid item className="GridBotonesCN">
                <Button className="BotonBody BotonCN" onClick={handleEnviar}>
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: isMobile ? "100vw" : "50vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              sx={{ fontWeight: "bold" }}
              target="_blank"
              underline="none"
              color="primary"
              href="https://www.favl.com.ar/"
            >
              https://www.favl.com.ar/
            </Link>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Contacto;
