import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { MenuContext } from "../../../context/MenuContext";
import { useGetListaHistorialQuery } from "../../../redux/services/historial";
import { ModalidadContext } from "../../../context/ModalidadContext";
import MenuLateral from "../../../components/MenuLateral";
import noimage from "../../../static/img/sinfoto.png";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  useGetHistorialRanikngIndividualMutation,
  useGetMostrarHistorialRankingModalidadQuery,
} from "../../../redux/services/ranking";
import { toast } from "react-toastify";

const HistorialRanking = () => {
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";
  const { menuState } = useContext(MenuContext);
  const [historialRanking, setHistorialRanking] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const state = useSelector((state) => state.modalidad);

  const { data: dataHistorial, isLoading: isLoadingHistorial } =
    useGetMostrarHistorialRankingModalidadQuery(state?.modalidad.id);
  const { modalidadState, setModalidadState } = useContext(ModalidadContext);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataHistorial?.listaArchivoRanking;
        const processedData = filtrado.map((item) => {
          const { month, year } = separateDate(item.fecha);
          return {
            ...item,
            mes: getMonthName(month),
            anio: year,
          };
        });
        setHistorialRanking(processedData);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataHistorial]);

  const totalPages = Math.ceil(historialRanking?.length / pageSize);

  const paginatedHistorial = historialRanking?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setModalidadState(state);

    return () => {
      setModalidadState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const opcMenu = [
    {
      opcion: "Acerca de...",
      link: "/modalidad/Cnd",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ModalidadCnd",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/ReglamentoCnd",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Records",
      link: "/RecordPublico",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  const opcMenu2 = [
    {
      opcion: "Novedades",
      link: "/modalidad/*",
      state,
    },
    {
      opcion: "Reglamento",
      link: "/Reglamento",
      state,
    },
    {
      opcion: "Historial",
      link: "/Historial-Publico",
      state,
    },
    {
      opcion: "Resultados",
      link: "/ResultadosPublicados",
      state,
    },
    {
      opcion: "Ranking",
      link: "/Ranking",
      state,
    },
    {
      opcion: "Historial Ranking",
      link: "/HistorialRanking",
      state,
    },
    {
      opcion: "Selección Argentina",
      link: "/SeleccionPublicada",
      state,
    },
  ];

  //Filtros
  const initialState = {
    mes: " ",
    anio: " ",
  };

  const [validationErrors, setValidationErrors] = useState({
    mes: false,
    anio: false,
  });

  const [historialBuscado, setHistorialBuscado] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setHistorialBuscado({
      ...historialBuscado,
      [name]: value,
    });
  };

  //Endpoint para traer con filtro
  const [
    getHistorialRankingIndividual,
    { isLoading: isLoadingHistorialRankingIndividual },
  ] = useGetHistorialRanikngIndividualMutation();

  const handleBuscar = async (e) => {
    e.preventDefault();
    try {
      if (
        historialBuscado?.mes === " " ||
        historialBuscado?.mes === null ||
        historialBuscado?.anio === " " ||
        historialBuscado?.anio === null
      ) {
        if (historialBuscado?.mes === " " || historialBuscado?.mes === null) {
          setValidationErrors({ mes: true });
          toast.error("¡El mes es obligatorio! ");
        }
        if (historialBuscado?.anio === " " || historialBuscado?.anio === null) {
          setValidationErrors({ anio: true });
          toast.error("¡El año es obligatorio! ");
        }
      } else {
        const fecha = `${historialBuscado.mes}-${historialBuscado.anio}`;
        const historial = {
          fecha,
          idModalidad: state?.modalidad.id,
        };
        const dataRecordBuscados = await getHistorialRankingIndividual(
          historial
        );
        if (dataRecordBuscados.error)
          throw new Error("Error al buscar el ranking");

        const filtrado2 = dataRecordBuscados.data?.listaArchivoRanking;

        const processedData = filtrado2.map((item) => {
          const { month, year } = separateDate(item.fecha);
          return {
            ...item,
            mes: getMonthName(month),
            anio: year,
          };
        });
        setHistorialRanking(processedData);
      }
    } catch (error) {
      toast.error("No se encontro resultados!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error Buscando Ranking:", error);
    }
  };

  const getMonthName = (monthNumber) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return months[monthNumber - 1];
  };

  const separateDate = (dateString) => {
    const [month, year] = dateString.split("-");
    return { month: parseInt(month, 10), year };
  };

  return (
    <React.Fragment>
      <Box style={{ marginTop: "5%" }}>
        <MenuLateral
          opcMenu={
            state?.modalidad.nombre.toLowerCase() === "cnd" ? opcMenu : opcMenu2
          }
          menuTitulo={state?.modalidad.nombre}
          typeMenu={false}
        />
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          {isLoadingHistorial && (
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                className="Titulo"
                variant="h5"
                style={{
                  width: "100%",
                  textAlign: "center",
                  zIndex: 1,
                  paddingRight: "5px",
                }}
              >
                Cargando ranking historico de {state?.modalidad.nombre}
              </Typography>
              <CircularProgress
                style={{ position: "relative", height: "15px", width: "15px" }}
              />
            </div>
          )}
          {!isLoadingHistorial &&
            paginatedHistorial &&
            paginatedHistorial.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} style={{ marginTop: "2%" }}>
                  <Typography
                    className="Titulo TituloPpalCN"
                    sx={{ textAlign: "center", marginLeft: "3%" }}
                  >
                    Buscar
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: "2%" }}>
                  <Box
                    sx={{ minWidth: "100%", maxWidth: "100%" }}
                    alignItems={"end"}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="mes-select-label">Mes</InputLabel>
                      <Select
                        labelId="mes-select-label"
                        id="mes-select"
                        name="mes"
                        value={historialBuscado.mes || ""}
                        label="mes"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value={" "}>--Seleccione el mes--</MenuItem>
                        <MenuItem value={"01"}>Enero</MenuItem>
                        <MenuItem value={"02"}>Febrero</MenuItem>
                        <MenuItem value={"03"}>Marzo</MenuItem>
                        <MenuItem value={"04"}>Abril</MenuItem>
                        <MenuItem value={"05"}>Mayo</MenuItem>
                        <MenuItem value={"06"}>Junio</MenuItem>
                        <MenuItem value={"07"}>Julio</MenuItem>
                        <MenuItem value={"08"}>Agosto</MenuItem>
                        <MenuItem value={"09"}>Septiembre</MenuItem>
                        <MenuItem value={"10"}>Octubre</MenuItem>
                        <MenuItem value={"11"}>Noviembre</MenuItem>
                        <MenuItem value={"12"}>Diciembre</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: "2%" }}>
                  <Box sx={{ minWidth: "100%", maxWidth: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="anio-select-label">Año</InputLabel>
                      <Select
                        labelId="anio-select-label"
                        id="anio-select"
                        name="anio"
                        value={historialBuscado.anio || ""}
                        label="anio"
                        onChange={handleChange}
                        required
                      >
                        <MenuItem value={" "}>--Seleccione el año--</MenuItem>
                        <MenuItem value={"2024"}>2024</MenuItem>
                        <MenuItem value={"2025"}>2025</MenuItem>
                        <MenuItem value={"2026"}>2026</MenuItem>
                        <MenuItem value={"2027"}>2027</MenuItem>
                        <MenuItem value={"2028"}>2028</MenuItem>
                        <MenuItem value={"2029"}>2029</MenuItem>
                        <MenuItem value={"2030"}>2030</MenuItem>
                        <MenuItem value={"2031"}>2031</MenuItem>
                        <MenuItem value={"2032"}>2032</MenuItem>
                        <MenuItem value={"2033"}>2033</MenuItem>
                        <MenuItem value={"2034"}>2034</MenuItem>
                        <MenuItem value={"2035"}>2035</MenuItem>
                        <MenuItem value={"2036"}>2036</MenuItem>
                        <MenuItem value={"2037"}>2037</MenuItem>
                        <MenuItem value={"2038"}>2038</MenuItem>
                        <MenuItem value={"2039"}>2039</MenuItem>
                        <MenuItem value={"2040"}>2040</MenuItem>
                        <MenuItem value={"2041"}>2041</MenuItem>
                        <MenuItem value={"2042"}>2042</MenuItem>
                        <MenuItem value={"2043"}>2043</MenuItem>
                        <MenuItem value={"2044"}>2044</MenuItem>
                        <MenuItem value={"2045"}>2045</MenuItem>
                        <MenuItem value={"2046"}>2046</MenuItem>
                        <MenuItem value={"2047"}>2047</MenuItem>
                        <MenuItem value={"2048"}>2048</MenuItem>
                        <MenuItem value={"2049"}>2049</MenuItem>
                        <MenuItem value={"2050"}>2050</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginTop: "2%" }}>
                  <LoadingButton
                    className="BotonBody BotonCN"
                    onClick={handleBuscar}
                  >
                    Buscar
                  </LoadingButton>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell
                          className="Titulo"
                          colSpan={3}
                          align="center"
                        >
                          <Typography variant="h5">
                            Ranking historico de {state?.modalidad.nombre}
                          </Typography>
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {paginatedHistorial.map((posicion, indice) => (
                          <TableRow key={indice}>
                            <TableCell align="left" style={{ width: "30%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Mes: {posicion.mes}
                                </span>
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ width: "50%" }}>
                              <Typography variant="subtitle1">
                                <span
                                  className="Titulo"
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Año: {posicion.anio}
                                </span>
                              </Typography>
                            </TableCell>
                            <TableCell align="left" style={{ width: "20%" }}>
                              <Typography variant="body1" className="Titulo">
                                Descarga:
                              </Typography>
                              {posicion.archivo === "null" ||
                              posicion.archivo === null ? (
                                <Typography style={{ marginLeft: "10px" }}>
                                  <FileDownloadOffIcon color="primary" />
                                </Typography>
                              ) : (
                                <IconButton
                                  component="a"
                                  href={`${API_BASE_URL}/images/${posicion?.archivo}`}
                                  download={`${posicion?.archivo}`}
                                  target="_blank"
                                >
                                  <GetAppIcon color="primary" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider sx={{ width: "100%", m: 2 }} />
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} / {totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              </Grid>
            )}
          {!isLoadingHistorial &&
            (!historialRanking || historialRanking.length === 0) && (
              <Typography className="Titulo">
                No hay Historial para mostrar.
              </Typography>
            )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HistorialRanking;
