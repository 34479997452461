import { LoadingButton } from "@mui/lab";
import PieDePagina from "../../../../../components/PieDePagina";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAgregarModalidadMutation } from "../../../../../redux/services/modalidad";
import BarraNavegacion from "../../../../../components/BarraNavegacion";

function CrearModalidad({ sinmenu, modalidadesCount }) {
  const [validationErrors, setValidationErrors] = useState({
    nombre: false,
    descripcion: false,
  });

  const initialState = {
    nombre: "",
    descripcion: "",
    imagen: "",
    reglamento: "",
    activo: true,
  };

  const [modalidad, setModalidad] = useState(initialState);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === 0,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalidad({
      ...modalidad,
      [name]: value,
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === 0,
    }));
  };

  const handleRequired = () => {
    const errors = {
      nombre: modalidad.nombre.trim() === "",
      descripcion: modalidad.descripcion.trim() === "",
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      toast.error("No se puede guardar debido a errores de validación!", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "success5",
      });
      return true;
    }

    return false;
  };

  const [agregarModalidad, { error, isLoading: isLoadingAgregar }] =
    useAgregarModalidadMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error(`Todos los datos son obligatorios!`);
      return;
    }

    try {
      const modId = await agregarModalidad(modalidad);
      if (modId.error) throw new Error(error);
      setModalidad(initialState);
      setFileNameRules("");
      toast.success("Modalidad creada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
      window.location.reload();
    } catch (error) {
      toast.error("Error creando la Modalidad!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    }
  };

  const [fileName, setFileName] = useState("");
  const [previewImgModalidadUrl, setPreviewImgModalidadUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setModalidad({
        ...modalidad,
        imagen: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgModalidadUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  const [fileNameRules, setFileNameRules] = useState("");

  return (
    <React.Fragment>
      <Box>
        {!sinmenu && <BarraNavegacion />}

        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Modalidad
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Nombre"
                  name="nombre"
                  value={modalidad.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.nombre}
                  helperText={
                    validationErrors.nombre && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-around" }}>
              <Grid item md={3} xs={11} style={{ marginBottom: "15px" }}>
                <Box className="BoxImagenCN">
                  <Typography className="Texto">Imagen</Typography>

                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imagenInput"
                  />
                  {modalidad.imagen && (
                    <img
                      src={previewImgModalidadUrl ? previewImgModalidadUrl : ""}
                      alt={fileName}
                      style={{
                        maxWidth: "50%",
                        height: "40%",
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: "16px",
                      }}
                    />
                  )}
                  <Divider className="DividerCN" />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <label htmlFor="imagenInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className="BotonBody BotonCN"
                        sx={{}}
                      >
                        Seleccionar
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Descripción"
                  name="descripcion"
                  value={modalidad.descripcion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  multiline
                  rows={9}
                  error={validationErrors.descripcion}
                  helperText={
                    validationErrors.descripcion && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <LoadingButton
                loading={isLoadingAgregar}
                className="BotonBody BotonCN"
                onClick={handlePublicar}
              >
                Publicar
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
}

export default CrearModalidad;
