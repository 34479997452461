import { Box, Button, IconButton, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import ModalEditarEliminarRecord from './ModalEditarEliminarRecord';
import InfoIcon from '@mui/icons-material/Info';
import { blue } from '@mui/material/colors';

function DataGridRecord({ rows }) {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      flex: 0,
      width: 1,
      headerClassName: 'hidden-column',
      cellClassName: 'hidden-column',
  },
  {
      field: 'piloto',
      headerName: 'Piloto',
      minWidth: 300,
      sortable: false,
  },
  {
      field: 'tipo',
      headerName: 'Tipo',
      width: 300,
      sortable: false,
      filterable: false,

  },
  {
      field: 'marca',
      headerName: 'Marca',
      width: 100,
      sortable: false,
      filterable: false,
  },
  {
      field: 'territorio',
      headerName: 'Territorio',
      width: 100,
      sortable: false,
      filterable: false,
  },
  {
      field: 'provincia',
      headerName: 'Provincia',
      sortable: false,
      valueGetter: (params) => params.value !== 'null' ? params.value : null,
      filterable: false,
  },
  {
      field: 'despegue',
      headerName: 'Ciudad/Despegue',
      minWidth: 150,
      sortable: false,
  },
  {
      field: 'estado',
      headerName: 'Estado',
      width: 100,
      sortable: false,
      filterable: false,
  },
  {
      field: 'fecha',
      headerName: 'Fecha de Vuelo',
      description: 'columna de fecha',
      sortable: false,
      minWidth: 120,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
  },
  {
      field: 'track',
      headerName: 'Link',
      sortable: false,
      minWidth: '100',
      renderCell: (params) => (
          params?.value !== ''
              ?
              <IconButton
                  component="a"
                  href={(params.value.startsWith('http://') || params.value.startsWith('https://')) ? params.value : `https://${params.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{ color: blue[700] }}
              >
                  <InfoIcon />
              </IconButton>
              :
              '--'
      ),
  },
    {
      headerName: "Acciones",
      sortable: false,
      minWidth: 200,

      renderCell: (params) => (
        <div>
          <Button onClick={() => openEditar(params.row)}>Editar</Button>
          <Button onClick={() => openEliminar(params.row)}>Eliminar</Button>
        </div>
      ),
    },
  ];

  const [openModal, setOpenModal] = useState();
  const [modalRow, setModalRow] = useState();
  const [modalAccion, setModalAccion] = useState();

    const openEditar = (row) => {
        setOpenModal(true);
        setModalRow(row);
        setModalAccion('Editar');
    };

    const openEliminar = (row) => {
        setOpenModal(true);
        setModalRow(row);
        setModalAccion('Eliminar');
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalRow(null);
        setModalAccion('');
    };
    const [sortModel] = useState([
        { field: 'estado', sort: 'desc' },
        { field: 'fecha', sort: 'asc' },
    ]);

    const HiddenHeaderIconGrid = styled(DataGrid)(({ theme }) => ({
        '& .MuiDataGrid-menuIcon': {
            display: 'none', 
        },
    }));

    return (
        <Box sx={{ height: '30%', width: '100%' }}>
            <HiddenHeaderIconGrid
                autoHeight
                style={{ maxWidth: '100%', minWidth: 'auto', }}
                rows={rows}
                columns={columns}
                sortModel={sortModel}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5]}
            />
            <ModalEditarEliminarRecord
                open={openModal}
                handleClose={handleCloseModal}
                row={modalRow}
                accion={modalAccion}
            />
        </Box>
    );
}

export default DataGridRecord;
