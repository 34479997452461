import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MenuContext } from "../../../../context/MenuContext";
import { fetchRankingData } from "../../../../redux/features/rankingSlice";
import { useGetListaCompetenciasQuery } from "../../../../redux/services/competencia";
import "../../../../routes/Styles/CrearNovedad.css";
import CompetenciasListado from "./componentes/CompetenciasListado";
import { clearDeleteResult } from "../../../../redux/features/deleteResultSlice";

const Resultados = () => {
  const { menuState } = useContext(MenuContext);
  const [competencias, setCompetencias] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { data, loading, error } = useSelector((state) => state.ranking);
  const handleCalcularRanking = async () => {
    handleOpen();
    try {
      let res = await dispatch(fetchRankingData());

      if (!loading)
        if (res.payload.ok !== true) {
          toast.error(`${res.payload.mensaje}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          throw new Error();
        } else {
          toast.success("Ranking calculado!", {
            position: toast.POSITION.TOP_CENTER,
          });
          window.location.reload();
        }
    } catch (error) {
      toast.error("Error calculando Ranking!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      handleClose();
    }
  };

  const {
    data: dataCompetencias,
    isLoading: isLoadingCompetencias,
    refetch,
  } = useGetListaCompetenciasQuery();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const datosFiltrados = dataCompetencias?.listaCompetencia
          ?.filter((dato) => {
            const hoy = dayjs();
            return (
              dayjs(dato.fechaInicio).isBefore(hoy, "date") ||
              dayjs(dato.fechaFin).isBefore(hoy, "date")
            );
          })
          .map((dato) => ({
            ...dato,
            fechaInicio: dayjs(dato.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: dayjs(dato.fechaFin).format("YYYY-MM-DD"),
          }))
          .filter((dato) => {
            return dato.activo !== false;
          });

        setCompetencias(datosFiltrados);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataCompetencias]);

  let resultDelete = localStorage.getItem("deleteResult");

  let isDeleteResultTrue = resultDelete === "true";
  
  if (isDeleteResultTrue) {
    dispatch(clearDeleteResult(null));
    window.location.reload();
  } 

  useEffect(() => {
    refetch();
  }, []);

  const totalPages = Math.ceil(competencias?.length / pageSize);

  const paginatedCompetencias = competencias?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Box>
        <Box
          className={`${
            menuState ? "contPrincipalPXC menuAbiertoPXC" : "contPrincipalPXC"
          }`}
        >
          <Grid container sx={{ height: "100%" }} spacing={2}>
            <Grid
              item
              xs={12}
              style={{ flexDirection: "column" }}
              className="gridColumnaPXC"
            >
              {paginatedCompetencias ? (
                <Box className="boxCalendarioPXC">
                  <div
                    style={{
                      marginTop: "2%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography className="Subtitulo">Calendario</Typography>
                      <Typography
                        className="Titulo"
                        sx={{ marginBottom: "15px" }}
                      >
                        Competencias:
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        onClick={handleCalcularRanking}
                      >
                        Calcular Ranking
                      </Button>
                    </div>
                  </div>

                  {paginatedCompetencias.length > 0 ? (
                    paginatedCompetencias.map((competencia) => (
                      <>
                        <CompetenciasListado
                          key={competencia.id}
                          competencia={competencia}
                          Origen={"auth"}
                        />
                      </>
                    ))
                  ) : (
                    <Typography
                      className="Titulo"
                      style={{ fontSize: 14, alignSelf: "center" }}
                    >
                      No hay competencias para mostrar.
                    </Typography>
                  )}
                </Box>
              ) : isLoadingCompetencias ? (
                <CircularProgress />
              ) : (
                <Typography className="Titulo">
                  {" No hay competencias para mostrar."}
                </Typography>
              )}

              {!isNaN(totalPages) && paginatedCompetencias?.length > 0 && (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Button
                    variant="outlined"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </Button>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 10px 0px 10px" }}
                  >
                    Pag.{currentPage} /{totalPages}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    {">>"}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div
          id="div1"
          style={{
            p: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ textAlign: "center", fontSize: 10 }}>Calculando... </p>
          <p style={{ textAlign: "center", color: "orange" }}>
            No actualice esta página
          </p>
        </div>
      </Backdrop>
    </>
  );
};

export default Resultados;
