import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BarraNavegacion from "../components/BarraNavegacion";
import PieDePagina from "../components/PieDePagina";
import { MenuContext } from "../context/MenuContext";
import { useGetCompetidorSeleccionModalidadQuery } from "../redux/services/competidor";
import { useGetListaModalidadesSelectQuery } from "../redux/services/modalidad";
import TablaSeleccionInicio from "./TablaSeleccionInicio";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SeleccionInicio() {
  const [seleccion, setSeleccion] = useState([]);
  const [idModalidad, setIdModalidad] = useState(14);
  const [nombreModalidad, setNombreModalidad] = useState("Parapente XC");
  const [dataModalidades, setDataModalidades] = React.useState();
  const {
    data: dataSeleccion,
    isLoading: isLoadingSeleccion,
    refetch,
  } = useGetCompetidorSeleccionModalidadQuery(idModalidad);
  const { data: resModalidades, isLoading: isLoadingModalidad } =
    useGetListaModalidadesSelectQuery();
  const [hasError, setHasError] = React.useState({
    modalidad: false,
  });

  React.useEffect(() => {
    try {
      setDataModalidades(resModalidades?.listarModalidades);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataModalidades();
    };
  }, [resModalidades]);

  useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const filtrado = dataSeleccion?.listaCompetidorSeleccion;
        setSeleccion(filtrado);
      } catch (error) {
        console.error("Error al obtener los posts:", error);
      }
    };

    fetchData();
  }, [dataSeleccion, idModalidad]);

  const handleChange = (e) => {
    const { value } = e.target;

    const datosFiltrados = dataModalidades?.filter((dato) => {
      return dato.id === value;
    });
    setNombreModalidad(datosFiltrados[0].nombre);
    setIdModalidad(value);
  };

  const { setMenuState } = React.useContext(MenuContext);

  React.useEffect(() => {
    setMenuState(false);

    return () => {
      setMenuState(true);
    };
  }, []);

  return (
    <React.Fragment>
      <BarraNavegacion />
      <Box className="contPrincipalI" style={{ margin: 0 }}>
        <Box
          sx={{ border: "1px solid transparent", height: "100%", py: "3rem" }}
        />
        <Grid container style={{ flexDirection: "row" }}>
          <Grid
            item
            xs={12}
            style={{ flexDirection: "column", marginTop: "0px" }}
            className="gridColumnaPXC"
          >
            <Box
              container
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%" }}></div>
              <div style={{ width: "50%" }}>
                {resModalidades ? (
                  <FormControl
                    fullWidth
                    sx={{ textAlign: "center" }}
                    error={hasError.modalidad}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Modalidad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idModalidad"
                      value={idModalidad}
                      label="Modalidad"
                      onChange={handleChange}
                      required
                    >
                      {dataModalidades?.map((modalidad) => (
                        <MenuItem key={modalidad.id} value={modalidad.id}>
                          {modalidad.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    {hasError.modalidad && (
                      <FormHelperText
                        sx={{ color: "#D32F2F", textAlign: "center" }}
                      >
                        Campo requerido!
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : isLoadingModalidad ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: "10px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        width: "50%",
                        height: "50%",
                        textAlign: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        style={{
                          color: "rgb(0, 150, 215)",
                          fontSize: "15px",
                          marginTop: "10px",
                        }}
                      >
                        Cargando Modalidad
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography
                      style={{ textAlign: "center" }}
                      className="Titulo"
                    >
                      No hay Modalidad para mostrar
                    </Typography>
                  </Grid>
                )}
              </div>
            </Box>
            {seleccion ? (
              <Box width={"100%"}>
                {seleccion?.length === 0 ? (
                  <Typography className="Titulo" style={{ margin: "20px" }}>
                    No hay Competidores en la Selección en esta modalidad.
                  </Typography>
                ) : (
                  <Box>
                    <TablaSeleccionInicio
                      seleccion={seleccion}
                      nombreModalidad={nombreModalidad}
                      idModalidad={idModalidad}
                    />
                  </Box>
                )}
              </Box>
            ) : isLoadingSeleccion ? (
              <CircularProgress style={{ marginTop: "50px" }} />
            ) : (
              <Box>
                <Typography
                  className="Titulo"
                  style={{
                    margin: "20px",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                >
                  No hay Competidores en la Selección en esta modalidad.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <PieDePagina />
    </React.Fragment>
  );
}

export default SeleccionInicio;
