import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authApi } from "./auth";
import { store } from "../store";
import { logout, setCredentials } from "../features/authSlice";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://localhost:5000";

const getAuthToken = () => {
  return localStorage.getItem("refreshToken");
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      const refresh = getAuthToken();

      if (refresh) {
        try {
          const refreshResult = await api.dispatch(
            authApi.endpoints.refreshToken.initiate({ refreshToken: refresh })
          );

          if (refreshResult.data.respuestaToken !== null) {
            const newToken = refreshResult.data.respuestaToken.accessToken;
            const newRefreshToken = refreshResult.data.respuestaToken.refreshToken;

            const userData = {
              respuestaToken: {
                accessToken: newToken,
                refreshToken: newRefreshToken,
              },
            };
            store.dispatch(setCredentials(userData));
            processQueue(null, newToken);

            result = await baseQuery(
              {
                ...args,
                headers: {
                  ...args.headers,
                  Authorization: `Bearer ${newToken}`,
                },
              },
              api,
              extraOptions
            );
          } else {
            processQueue("Token refresh failed", null);
            store.dispatch(logout());
            toast.error(
              "Lo sentimos, tu sesión ha expirado debido a inactividad. Por favor, vuelve a iniciar sesión para continuar.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        } catch (error) {
          processQueue(error, null);
          store.dispatch(logout());
          toast.error(
            "Error al intentar refrescar el token. Por favor, vuelve a iniciar sesión.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } finally {
          isRefreshing = false;
        }
      } else {
        store.dispatch(logout());
        toast.error(
          "No se encontró un token de refresco. Por favor, vuelve a iniciar sesión.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
      .then(token => {
        result = baseQuery(
          {
            ...args,
            headers: {
              ...args.headers,
              Authorization: `Bearer ${token}`,
            },
          },
          api,
          extraOptions
        );
        return result;
      })
      .catch(err => {
        return result;
      });
    }
  }

  return result;
};

export const competenciaApi = createApi({
  reducerPath: "competenciaApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Competencias"],
  endpoints: (builder) => ({
    getListaCompetencias: builder.query({
      query: () => "/buscar-lista-competencias",
      providesTags: ["Competencias"],
    }),
    getListaCompetenciasCms: builder.query({
      query: () => "/buscar-lista-competencias-cms",
      providesTags: ["Competencias"],
    }),
    agregarCompetencia: builder.mutation({
      query: (nuevaCompetencia) => {
        const formData = new FormData();
        Object.keys(nuevaCompetencia).forEach((key) => {
          formData.append(String(key), nuevaCompetencia[key]);
        });
        return {
          url: `agregar-competencia`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Competencias"],
    }),
    modificarCompetencia: builder.mutation({
      query: ({ id, nuevaCompetencia }) => {
        const formData = new FormData();
        Object.keys(nuevaCompetencia).forEach((key) => {
          formData.append(String(key), nuevaCompetencia[key]);
        });
        return {
          url: `/modificar-competencia`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Competencias"],
    }),
    borrarCompetenciaById: builder.mutation({
      query: (id) => ({
        url: `/borrar-competencia?Id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Competencias"],
    }),
    getCompetenciaById: builder.query({
      query: (id) => `/buscar-competencia-individual?id=${id}`,
    }),
    getImagenesByCompetencia: builder.query({
      query: (id) => `/obtener-imagen-competencia?idCompetencia=${id}`,
    }),
    agregarImagenCompetencia: builder.mutation({
      query: ({ imagen, idCompetencia }) => {
        const formData = new FormData();
        formData.append("idCompetencia", idCompetencia);
        formData.append("imagen", imagen);

        return {
          url: `/agregar-imagen-competencia`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Competencias"],
    }),
  }),
});

export const {
  useGetListaCompetenciasQuery,
  useGetListaCompetenciasCmsQuery,
  useAgregarCompetenciaMutation,
  useAgregarImagenCompetenciaMutation,
  useModificarCompetenciaMutation,
  useGetCompetenciaByIdQuery,
  useGetImagenesByCompetenciaQuery,
  useBorrarCompetenciaByIdMutation,
} = competenciaApi;
