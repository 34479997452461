import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function TableHistorial({ historial }) {
  const [temporada, setTemporada] = React.useState(historial);

  React.useEffect(() => {
    setTemporada(historial);
  }, [historial]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="Titulo">Licencia</TableCell>
            <TableCell align="left" className="Titulo">
              Nombre y Apellido
            </TableCell>
            <TableCell align="left" className="Titulo">
              Ciudad
            </TableCell>
            <TableCell align="left" className="Titulo">
              Provincia
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {temporada.posiciones?.map((posicion, indice) => (
            <TableRow
              key={indice}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong style={{ fontWeight: "900" }}>{indice + 1}º</strong>
                &nbsp; {posicion.id}
              </TableCell>
              <TableCell align="left">{posicion.nombre}</TableCell>
              <TableCell align="left">{posicion.ciudad}</TableCell>
              <TableCell align="left">{posicion.provincia}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
