import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import {
  useBorrarModalidadMutation,
  useModificarModalidadMutation,
} from "../../../../../redux/services/modalidad";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function ModalModalidad({
  ModalState,
  CloseModal,
  Modalidad,
  CurrentId,
  Origen = "readonly",
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [newData, setNewData] = useState(Modalidad);
  const formRef = React.useRef();
  const [validationErrors, setValidationErrors] = useState({
    nombre: false,
    descripcion: false,
  });
  const { open, ModalConfirmacion } = useConfirmationModal();
  const [modificarModalidad, { isLoading: isLoadingModificar }] =
    useModificarModalidadMutation();

  const handleEdit = async () => {
    if (validationErrors.descripcion) {
      toast.error("descripcion es campo obligatorio!");
      return;
    }
    if (validationErrors.nombre) {
      toast.error("nombre es campo obligatorio!");
      return;
    }
    if (validationErrors.modificador) {
      toast.error("modificador es campo obligatorio!");
      return;
    }
    try {
      const resp = await modificarModalidad(newData);
      if (resp.error) throw new Error(resp.error);
      toast.success("Modalidad actualizada con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
      CloseModal();
      window.location.reload();
    } catch (error) {
      toast.error("Error Actualizando Modalidad!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
    setIsEditable(false);
  };

  const [borrarModalidad, error] = useBorrarModalidadMutation();

  const handleDelete = async () => {
    try {
      const result = await borrarModalidad(CurrentId);
      if (result.error) throw new Error(error);

      toast.success("Modalidad eliminada con Éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success2",
      });
      setIsDelete(false);
      window.location.reload();
    } catch (error) {
      setIsDelete(false);
      console.log(error);

      toast.error("Error Borrando Modalidad!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
  };

  const handleCancelar = () => {
    setIsEditable(false);
    setIsDelete(false);
    setNewData(Modalidad);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === 0,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewData({
      ...newData,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === 0,
    }));
  };

  const handleCloseModal = () => {
    CloseModal();
    setIsEditable(false);
    setIsDelete(false);
  };

  const [fileName, setFileName] = useState("");
  const [previewImgModalidadUrl, setPreviewImgModalidadUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setNewData({
        ...newData,
        imagen: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgModalidadUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  const [fileNameRules, setFileNameRules] = useState("");

  return (
    <React.Fragment>
      <Modal
        key={Modalidad.id}
        open={ModalState}
        onClose={handleCloseModal}
        className="ModalC"
      >
        <Box className="ContenedorMC" style={{ width: "80%" }}>
          <Box className="ContSecundarioMC">
            <form ref={formRef}>
              <Grid container sx={{ justifyContent: "space-between" }}>
                {isEditable ? (
                  <>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        required
                        label="Nombre"
                        aria-label="Nombre"
                        className="Nombre"
                        defaultValue={Modalidad.nombre}
                        value={newData.nombre}
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={validationErrors.nombre}
                        helperText={
                          validationErrors.nombre && "Este campo es requerido"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={5}></Grid>
                  </>
                ) : (
                  <Grid item xs={5} md={9}>
                    <Typography className="Titulo">
                      {Modalidad.nombre}
                    </Typography>
                  </Grid>
                )}
                {Origen === "auth" && (
                  <Box xs={5} md={3}>
                    <IconButton
                      disabled={isDelete}
                      onClick={() => {
                        setIsEditable(!isEditable);
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled={isEditable}
                      onClick={() => {
                        setIsDelete(!isDelete);
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>
              <Divider className="DividerMC" />
              {isEditable ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      multiline
                      rows={9}
                      label="Descripción"
                      aria-label="descripcion"
                      className="Subtitulo"
                      style={{ whiteSpace: "pre" }}
                      defaultValue={Modalidad.descripcion}
                      value={newData.descripcion}
                      name="descripcion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={validationErrors.descripcion}
                      helperText={
                        validationErrors.descripcion &&
                        "Este campo es requerido"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Box
                    sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}
                  />
                  <Grid item xs={12} sm={5} className="GridItemCN">
                    <Box className="BoxImagenCN">
                      <Typography className="Texto">Imagen</Typography>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        id="imagenInputEdit"
                      />
                      {(newData.imagen || Modalidad.imagen) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            objectFit: "fill",
                            maxHeight: "50%",
                          }}
                        >
                          <img
                            src={
                              previewImgModalidadUrl
                                ? previewImgModalidadUrl
                                : Modalidad.imagen
                            }
                            alt={fileName}
                            style={{ maxWidth: "100%" }}
                          />
                        </Box>
                      )}
                      <Divider className="DividerCN" />
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <label htmlFor="imagenInputEdit">
                          <Button
                            variant="outlined"
                            component="span"
                            color="primary"
                            className="BotonBody BotonCN"
                            sx={{}}
                          >
                            Seleccionar
                          </Button>
                        </label>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Box className="ContenidoN">
                  <Grid container className="GridContenedorN">
                    {isDelete && (
                      <Grid item>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            pb: 5,
                          }}
                        >
                          ¿Desea Eliminar esta Modalidad?
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <TextField
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        readOnly
                        fullWidth
                        multiline
                        sx={{ whiteSpace: "pre", borderColor: "transparent" }}
                        value={Modalidad.descripcion}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Divider className="DividerMC" />
              <Grid className="gridBotonesMC">
                {!isEditable ? (
                  isDelete ? (
                    <>
                      <Grid container sx={{ justifyContent: "end" }}>
                        <Button onClick={handleDelete} className="BotonBody">
                          Eliminar
                        </Button>
                        <Button onClick={handleCancelar} className="BotonBody">
                          Cancelar
                        </Button>
                      </Grid>
                      <ModalConfirmacion />
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <Grid container sx={{ justifyContent: "end" }}>
                    <LoadingButton
                      loading={isLoadingModificar}
                      onClick={handleEdit}
                      className="BotonBody"
                    >
                      Guardar
                    </LoadingButton>
                    <Button onClick={handleCancelar} className="BotonBody">
                      Cancelar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default ModalModalidad;
