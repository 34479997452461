import React, { useState } from "react";
import "./Styles/InicioSesion.css";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../redux/services/auth";
import { setCredentials } from "../redux/features/authSlice";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function InicioSesion() {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ correo: correo, password }).unwrap();
      dispatch(setCredentials(userData));
      navigate("/cms");
    } catch (error) {
      toast.error("Usuario y/o contraseña incorrecto!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Failed to log in:", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <form className="FormularioIS">
        <Box className="BoxIS">
          <Typography
            className="TituloPXC"
            style={{ textAlign: "center", fontSize: "2rem" }}
          >
            Iniciar sesion
          </Typography>
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            size="small"
          />
          <TextField
            label="Contraseña"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            className="BotonNI BotonIS"
            style={{ width: 100 }}
            variant="contained"
            color="primary"
            onClick={handleLogin}
            loading={isLoading}
          >
            Iniciar Sesión
          </LoadingButton>
        </Box>
      </form>

      <div style={{ textAlign: "center" }}></div>
    </React.Fragment>
  );
}

export default InicioSesion;
