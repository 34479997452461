import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import { useAgregarCategoriaMutation } from "../../../../../redux/services/categoria";

const CrearCategoria = ({ sinmenu }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
  });

  const initialState = {
    nombre: "",
    activo: true,
  };

  const [categoria, setCategoria] = React.useState(initialState);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoria({
      ...categoria,
      [name]: value,
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "",
    }));
  };

  const handleRequired = () => {
    const errors = {
      nombre: categoria.nombre.trim() === "",
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }
    return false;
  };

  const [agregarCategoria, error] = useAgregarCategoriaMutation();

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      const modId = await agregarCategoria(categoria);
      if (modId.error) throw new Error(error);
      setCategoria(initialState);
      toast.success("Categoría creada!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
    } catch (error) {
      toast.error("Error creando la Categoría!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating Categoría:", error);
    }
  };

  return (
    <React.Fragment>
      <Box>
        {!sinmenu && <BarraNavegacion />}
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Crear Categoría
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} className="GridItemCN">
                <TextField
                  sx={{ mx: 1 }}
                  label="Nombre"
                  name="nombre"
                  value={categoria.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  error={validationErrors.nombre}
                  helperText={
                    validationErrors.nombre && "Este campo es requerido"
                  }
                />
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Publicar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CrearCategoria;
