import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import BarraNavegacion from "../../../../../components/BarraNavegacion";
import PieDePagina from "../../../../../components/PieDePagina";
import {
  useAgregarCompetidorMutation,
  useGetCompetidorByIdQuery,
  useModificarCompetidorMutation,
} from "../../../../../redux/services/competidor";
import { useGetListaClubQuery } from "../../../../../redux/services/club";

const CrearPiloto = ({ sinmenu }) => {
  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
    ciudad: false,
    provincia: false,
    idCompetidor: false,
  });

  const initialState = {
    nombre: "",
    idCompetidor: 0,
    idClub: 0,
    ciudad: "",
    provincia: "",
    foto: "",
    activo: true,
    nacionalidad: "ARG",
    genero: "",
  };

  const [legajo, setLegajo] = React.useState();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let errorMessage = "";
    if (name === "idCompetidor") {
      if (value == 0 || isNaN(value)) {
        errorMessage = "Este campo es requerido";
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    }

    setPiloto({
      ...piloto,
      [name]: value,
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage !== "",
    }));
  };

  const handleBlur = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let errorMessage = "";

    if (name === "idCompetidor") {
      if (value == 0 || isNaN(value)) {
        errorMessage = "Este campo es requerido";

        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      } else {
        setLegajo(value);

        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value.trim() === "",
        }));
      }
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const { data: dataLicencia } = useGetCompetidorByIdQuery(legajo);

  React.useEffect(() => {
    const fetchLicencia = async () => {
      try {
        let errorMessage = "";
        if (legajo) {
          const result = await dataLicencia;
          if (result) {
            if (result.competidorIndividual.activo === false) {
              toast.error("El competidor fue eliminado con esa Licencia!");
              errorMessage = "El competidor fue eliminado con esa Licencia!";
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                idCompetidor: errorMessage,
              }));
              setPiloto((prevPiloto) => ({
                ...prevPiloto,
                nombre: result.competidorIndividual.nombre,
                genero: result.competidorIndividual.genero,
                club: result.competidorIndividual.idClub,
                ciudad: result.competidorIndividual.ciudad,
                provincia: result.competidorIndividual.provincia,
                activo: false,
              }));
            } else if (result.competidorIndividual.idCompetidor !== 0) {
              toast.error(
                `Ya existe un competidor: ${result.competidorIndividual.nombre} con esa Licencia!`
              );
              errorMessage = `Ya existe un competidor: ${result.competidorIndividual.nombre} con esa Licencia!`;
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                idCompetidor: errorMessage,
              }));
            }
          }
        }
      } catch (error) {
        console.error("Error obteniendo datos de la licencia:", error);
        toast.error("El competidor no se encuentra cargado!");
        return;
      }
    };

    if (legajo) {
      fetchLicencia(legajo);
    }
  }, [dataLicencia]);

  const initialStateEdit = {
    nombre: "",
    idCompetidor: 0,
    idClub: 0,
    ciudad: "",
    provincia: "",
    foto: "",
    activo: false,
    nacionalidad: "ARG",
    genero: "",
  };

  const [piloto, setPiloto] = React.useState(initialState);
  const [pilotoEdit, setPilotoEdit] = React.useState(initialStateEdit);
  const [fileName, setFileName] = useState("");

  const handleRequired = () => {
    const errors = {
      nombre: piloto.nombre.trim() === "",
      ciudad: piloto.ciudad.trim() === "",
      provincia: piloto.provincia.trim() === "",
      idCompetidor: piloto.idCompetidor === 0 || isNaN(piloto.idCompetidor),
    };

    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      console.log("No se puede guardar debido a errores de validación");
      return true;
    }
    return false;
  };

  const [agregarCompetidor, error] = useAgregarCompetidorMutation();
  const [modificarCompetidor, { error: errorCompetidor }] =
    useModificarCompetidorMutation();

  const [dataClub, setDataClub] = React.useState();
  const { data: resClub, isLoading: isLoadingClub } = useGetListaClubQuery();
  const [hasError, setHasError] = React.useState({
    club: false,
  });

  React.useEffect(() => {
    try {
      setDataClub(resClub?.listaClub);
    } catch (error) {
      console.log("Error al obtener los modalidades:", error);
    }

    return () => {
      setDataClub();
    };
  }, [resClub]);

  const handlePublicar = async (e) => {
    e.preventDefault();

    if (handleRequired()) {
      toast.error("Todos los datos son obligatorios!");
      return;
    }

    try {
      if (piloto.activo === false) {
        const result = await modificarCompetidor(pilotoEdit);
        if (result.errorCompetidor) throw new Error(error);
        setPiloto(initialState);
        setFileName("");
        toast.success("Piloto editado!", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "success3",
        });
      } else {
        const modId = await agregarCompetidor(piloto);
        if (modId.error) throw new Error(error);
        setPiloto(initialState);
        setFileName("");
        toast.success("Piloto creado!", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "success3",
        });
      }
    } catch (error) {
      toast.error("Error creando la Piloto!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Error creating post:", error);
    }
  };

  const [previewImgPilotoUrl, setPreviewImgPilotoUrl] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    if (file) {
      setPiloto({
        ...piloto,
        foto: file,
      });
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = URL.createObjectURL(file);
        setPreviewImgPilotoUrl(url);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeSwitch = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPilotoEdit({
      ...piloto,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Box style={{ width: "100%" }}>
        {!sinmenu && <BarraNavegacion />}
        <Box className="ContenidoPpalCN">
          <Grid container className="GridPpalCN">
            <Grid item className="GridItemCN">
              <Typography className="Titulo TituloPpalCN">
                Nuevo Piloto
              </Typography>
            </Grid>
            <Divider className="DividerCN" />
            <Grid container justifyContent={"center"}>
              <Grid item md={4} xs={12} className="GridItemCN">
                <Box className="BoxImagenCN">
                  <Typography className="Texto">Foto</Typography>

                  <input
                    label="Foto"
                    name="foto"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imagenInput"
                  />
                  {piloto.foto && (
                    <img
                      src={previewImgPilotoUrl}
                      alt={fileName}
                      style={{ maxWidth: "100%", marginTop: "16px" }}
                    />
                  )}
                  <Divider className="DividerCN" />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <label htmlFor="imagenInput">
                      <Button
                        variant="outlined"
                        component="span"
                        color="primary"
                        className="BotonBody BotonCN"
                        sx={{}}
                      >
                        Seleccionar
                      </Button>
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={7} xs={12}>
                <Grid item xs={12} className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Licencia"
                    name="idCompetidor"
                    value={piloto.idCompetidor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.idCompetidor}
                    helperText={validationErrors.idCompetidor}
                  />
                </Grid>
                <Grid item xs={12} className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Nombre"
                    name="nombre"
                    value={piloto.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.nombre}
                    helperText={
                      validationErrors.nombre && "Este campo es requerido"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="GridItemCN"
                  style={{ margin: "10px" }}
                >
                  <FormControl fullWidth sx={{ textAlign: "center" }}>
                    <InputLabel id="demo-simple-select-label">
                      Genero
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="genero"
                      value={piloto.genero}
                      label="Genero"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={"M"}>Masculino</MenuItem>
                      <MenuItem value={"F"}>Femenino</MenuItem>
                      <MenuItem value={"X"}>Otro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="GridItemCN"
                  style={{ margin: "10px" }}
                >
                  {resClub ? (
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "center" }}
                      error={hasError.club}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Club
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="idClub"
                        value={piloto.idClub}
                        label="Club"
                        onChange={handleChange}
                        required
                      >
                        {dataClub?.map((club) => (
                          <MenuItem key={club.id} value={club.id}>
                            {club.sigla}
                          </MenuItem>
                        ))}
                      </Select>
                      {hasError.club && (
                        <FormHelperText
                          sx={{ color: "#D32F2F", textAlign: "center" }}
                        >
                          Campo requerido!
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : isLoadingClub ? (
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          width: "50%",
                          height: "50%",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ width: "30px", height: "30px" }}
                        />
                        <Typography
                          style={{
                            color: "rgb(0, 150, 215)",
                            fontSize: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Cargando Clubes
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className="Titulo">
                      {" No hay Clubes para mostrar."}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Ciudad"
                    name="ciudad"
                    value={piloto.ciudad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.ciudad}
                    helperText={
                      validationErrors.ciudad && "Este campo es requerido"
                    }
                  />
                </Grid>
                <Grid item xs={12} className="GridItemCN">
                  <TextField
                    sx={{ mx: 1 }}
                    label="Provincia"
                    name="provincia"
                    value={piloto.provincia}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    fullWidth
                    error={validationErrors.provincia}
                    helperText={
                      validationErrors.provincia && "Este campo es requerido"
                    }
                  />
                </Grid>
                {piloto.activo ? (
                  <Box></Box>
                ) : (
                  <Grid item xs={12} className="GridItemCN">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Activar
                      </InputLabel>
                      <Select
                        sx={{ mx: 1 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="activo"
                        value={pilotoEdit.activo}
                        label="Activar"
                        onChange={handleChangeSwitch}
                        required
                      >
                        <MenuItem value={true}>Restaurar</MenuItem>
                        <MenuItem value={false}>Borrar</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider className="DividerCN" />
            <Grid item className="GridBotonesCN">
              <Button className="BotonBody BotonCN" onClick={handlePublicar}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!sinmenu && <PieDePagina />}
      </Box>
    </React.Fragment>
  );
};

export default CrearPiloto;
