import { createSlice } from '@reduxjs/toolkit';


const modalidadSlice = createSlice({
  name: 'modalidad',
  initialState: {
    modalidad: null,
  },
  reducers: {
    setModalidad: (state, action) => {
      state.modalidad = action.payload;
    },
    clearModalidad: (state) => {
      state.modalidad = null;
    },
  },
});

export default modalidadSlice.reducer;
export const { setModalidad, clearModalidad } = modalidadSlice.actions;