import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal ";
import {
  useBorrarCategoriaMutation,
  useModificarCategoriaMutation,
} from "../../../../../redux/services/categoria";

const ModalCategoria = ({
  ModalState,
  CloseModal,
  Tipo,
  CurrentId,
  Origen = "readonly",
}) => {
  const [isEditable, setIsEditable] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [newData, setNewData] = React.useState(Tipo);
  const formRef = React.useRef();

  const [validationErrors, setValidationErrors] = React.useState({
    nombre: false,
    modificador: false,
  });
  const { ModalConfirmacion } = useConfirmationModal();

  const [modificarCategoria] = useModificarCategoriaMutation();

  const handleEdit = async () => {
    if (validationErrors.nombre) {
      toast.error("nombre es campo obligatorio!");
      return;
    }
    if (validationErrors.modificador) {
      toast.error("modificador es campo obligatorio!");
      return;
    }
    try {
      await modificarCategoria(newData);
      toast.success("Categoría actualizada con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
      CloseModal();
    } catch (error) {
      toast.error("Error Actualizando Categoría!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
    setIsEditable(false);
  };

  const [borrarCategoria] = useBorrarCategoriaMutation();

  const handleDelete = async () => {
    try {
      await borrarCategoria(CurrentId);
      toast.success("Categoría eliminada con éxito!", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
    } catch (error) {
      toast.error("Error Borrando Categoria!", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log(error);
    }
    setIsDelete(false);
  };

  const handleCancelar = () => {
    setIsEditable(false);
    setIsDelete(false);
    setNewData({});
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "modificador")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value == 0 || isNaN(value),
      }));
    else
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({
      ...newData,
      [name]: value,
    });

    if (name === "modificador")
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value == 0 || isNaN(value),
      }));
    else
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
  };

  const handleCloseModal = () => {
    CloseModal();
    setIsEditable(false);
    setIsDelete(false);
  };

  return (
    <React.Fragment>
      <Modal
        key={Tipo.id}
        open={ModalState}
        onClose={handleCloseModal}
        className="ModalC"
      >
        <Box className="ContenedorMC" style={{ width: "80%" }}>
          <Box className="ContSecundarioMC">
            <form ref={formRef}>
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Box flexGrow={1} display="flex" />
                {Origen === "auth" && (
                  <Box xs={5} md={3}>
                    <IconButton
                      disabled={isDelete}
                      onClick={() => {
                        setIsEditable(!isEditable);
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled={isEditable}
                      onClick={() => {
                        setIsDelete(!isDelete);
                      }}
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>
              <Divider className="DividerMC" />
              <Box className="ContenidoN">
                <Grid container className="GridContenedorN">
                  {isDelete && (
                    <Grid item>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          pb: 5,
                        }}
                      >
                        ¿Desea Eliminar esta Categoría?
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {isEditable ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                      <TextField
                        fullWidth
                        required
                        label="Nombre"
                        aria-label="Nombre"
                        className="Nombre"
                        defaultValue={Tipo.nombre}
                        value={newData.nombre}
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={validationErrors.nombre}
                        helperText={
                          validationErrors.nombre && "Este campo es requerido"
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={9} sx={{ mb: 5 }}>
                      <Typography className="Titulo" sx={{ mb: 5 }}>
                        {Tipo.nombre}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Divider className="DividerMC" />
              <Grid className="gridBotonesMC">
                {!isEditable ? (
                  isDelete ? (
                    <>
                      <Grid container sx={{ justifyContent: "end" }}>
                        <Button onClick={handleDelete} className="BotonBody">
                          Eliminar
                        </Button>
                        <Button onClick={handleCancelar} className="BotonBody">
                          Cancelar
                        </Button>
                      </Grid>
                      <ModalConfirmacion />
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <Grid container sx={{ justifyContent: "end" }}>
                    <Button onClick={handleEdit} className="BotonBody">
                      Guardar
                    </Button>
                    <Button onClick={handleCancelar} className="BotonBody">
                      Cancelar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ModalCategoria;
