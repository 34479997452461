import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import ModalModalidad from "./ModalModalidad";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CalendarioModalidades({ Modalidad, Origen }) {
  const [modalState, setModalState] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const handleOpenModal = () => {
    setCurrentId(Modalidad.id);
    setModalState(true);
  };

  const handleCloseModal = () => {
    setModalState(false);
  };

  return (
    <React.Fragment>
      <Accordion className="Acordeon">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="AcordeonSummary"
        >
          <Typography className="Titulo" sx={{ width: "75%" }}>
            {Modalidad.nombre}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ width: "auto", height: "auto", m: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails className="AcordeonDetail ">
          <Typography className="Texto truncate-text">
            {Modalidad.modificador}
          </Typography>
          <Button className="BotonBody" onClick={handleOpenModal}>
            Ver mas
          </Button>
          <div style={{ display: "flex" }}>
            <ModalModalidad
              ModalState={modalState}
              CloseModal={handleCloseModal}
              Modalidad={Modalidad}
              CurrentId={currentId}
              Origen={Origen}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
export default CalendarioModalidades;
